import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { useSelector } from 'react-redux'
import { message } from 'antd'

import { storeComment } from '../services'
import { handleError } from '../../../helpers'

export default function NewCommentForm({ modelType, modelId, reload, onClose }) {
	const { handleSubmit, register, formState: { errors }, reset } = useForm()
	const auth = useSelector(state => state.auth)

	const onSubmit = values => {
		storeComment({
			commentable_type: modelType,
			commentable_id: modelId,
			user_id: auth.user.id,
			...values
		})
			.then(() => {
				message.success('Comentario agregado exitosamente')
				reset()
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<Form.Label>Comentario</Form.Label>
					<Form.Control 
						as="textarea"
						{...register('body', { required: true })}
						placeholder="Escriba aquí..."
					/>
					{ errors.body && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Button color="primary" type="submit">
					Agregar comentario
				</Button>
			</Form>
		</React.Fragment>
	)
}