import springApi from '../../services/springApi'

export const getMetricTemplates = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/metric_templates', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeMetricTemplate = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/metric_templates', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateMetricTemplate = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/metric_templates/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteMetricTemplate = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/metric_templates/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)