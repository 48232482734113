import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faChartArea, faStore, faCalendarCheck, faBinoculars, faUsers } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function VisitsDashboard() {
	return (
		<React.Fragment>
			<PageTitle title="Rutas y Visitas a PDV" subtitle="Gestionar rutas, registros de visitas, frecuencias sugeridas, horarios de tiendas y registros de CheckIns." />
			<Row>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Listados de Visitas"
						subtitle="Consulta de visitas programadas, canceladas o finalizadas"
						icon={faBinoculars}
						linkTo={"/visits"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="CheckIn Registrados"
						subtitle="Consultar listado de check in registrados desde la app"
						icon={faCalendarCheck}
						linkTo={"/checkins"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Directorio de Tiendas"
						subtitle="Consultar, crear y actualizar listado de tiendas"
						icon={faStore}
						linkTo={"/stores"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Directorio de Usuarios"
						subtitle="Consultar, crear y actualizar usuarios de la plataforma"
						icon={faUsers}
						linkTo={"/users"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Reporte de visitas por asesor"
						subtitle="Reporte de cumplimiento y gestión de rutas y visitas programadas"
						icon={faChartArea}
						linkTo={"/reports/visits"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}