import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import CompetitorForm from './CompetitorForm'
import { storeCompetitor } from '../services'
import { handleError } from '../../../helpers'

export default function NewCompetitorForm({ reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm();

	const onSubmit = values => {
		storeCompetitor(values)
			.then(() => {
				message.success('Competidor creado exitosamente.')
				reload()
				reset()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<CompetitorForm register={register} errors={errors} />
				<Button type="submit" color="primary">Crear Competidor</Button>
			</Form>
		</React.Fragment>
	)
}