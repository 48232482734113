import React, { useState } from 'react'
import { message } from 'antd'
import { Button, InputGroup } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { handleError } from '../../../helpers'
import { searchStores, storeModelStore } from '../services'

export default function ModelStoreForm({ type, id, reload }) {
	const [selectedStore, setSelectedStore] = useState([])

	const onSubmit = () => {
		if(!selectedStore) 
			message.warn('Debe seleccionar una tienda')

		selectedStore && storeModelStore({
			model_type: `App\\Models\\${type}`,
			model_id: id,
			store_id: selectedStore.value
		})
			.then(() => {
				message.success('Tienda asociada exitosamente')
				setSelectedStore([])
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<InputGroup className="mt-2">
				<ServerSideSelect
					value={selectedStore}
					placeholder="Seleccione una tienda"
					fetchOptions={searchStores}
					onChange={value => setSelectedStore(value)}
					style={{ flex: 1,  }}
				/>
				<InputGroup.Append>
					<Button 
						variant="primary" 
						onClick={onSubmit}
						style={{ height: '32px', padding: '0px 5px' }}
					>
						Asociar Tienda
					</Button>
				</InputGroup.Append>
			</InputGroup>
		</React.Fragment>
	)
}