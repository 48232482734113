import springApi from '../../services/springApi'

export const getTaskLists = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/task_lists', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeTaskList = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/task_lists', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateTaskList = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/task_lists/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteTaskList = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/task_lists/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)