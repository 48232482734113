import React from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { getUri } from '../../../services/springApi'
import { storeExportToken } from '../../ExportTokens/services'
import { handleError } from '../../../helpers'

export default function ExportVisitsModal({ visible, onClose }) {
	const { user } = useSelector(state => state.auth)
	const { register, handleSubmit, formState: { errors } } = useForm({})

	const onSubmit = values => {
		const token = Date.now() + Math.floor(1000 + Math.random() * 9000)

		storeExportToken({ user_id: user.id, type: 'Exportar Visitas', token })
			.then(() => {
				message.info('Descarga en iniciada correctamente')
				window.open(`${getUri()}/exports/visits/${token}?filter[schedule_at]=${values.year}-${values.month}`)
				onClose()
			})
			.catch(handleError)		
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Exportar Visitas</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Año a exportar</Form.Label>
							<Form.Control
								as="select"
								{...register('year', { required:true })}
							>
								<option value="">:: Seleccione un año ::</option>
								<option>2024</option>
								<option>2023</option>
								<option>2022</option>
								<option>2021</option>
								<option>2020</option>
							</Form.Control>
							{ errors.year && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Mes a exportar</Form.Label>
							<Form.Control
								as="select"
								{...register('month', { required: true })}
							>
								<option value="">:: Seleccione una opción ::</option>
								<option value="01">Enero</option>
								<option value="02">Febrero</option>
								<option value="03">Marzo</option>
								<option value="04">Abril</option>
								<option value="05">Mayo</option>
								<option value="06">Junio</option>
								<option value="07">Julio</option>
								<option value="08">Agosto</option>
								<option value="09">Septiembre</option>
								<option value="10">Octubre</option>
								<option value="11">Noviembre</option>
								<option value="12">Diciembre</option>
							</Form.Control>
							{ errors.month && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Button color="primary" type="submit">Exportar Visitas</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}