import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import { storeUser } from '../services'
import { handleError } from '../../../helpers'
import UserForm from './UserForm'

export default function NewUserForm({ reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()
	const [selectedStore, setSelectedStore] = useState([])

	const onSubmit = values => {
		values.password = values.document.slice(values.document.length - 4)
		values.password_confirmation = values.password
		values.current_store = selectedStore.value
		storeUser(values)
			.then(() => {
				message.success('Usuario creado exitosamente.')
				reload()
				reset()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<UserForm 
					register={register} 
					errors={errors} 
					selectedStore={selectedStore}
					setSelectedStore={setSelectedStore}
				/>
				<Button type="submit" color="primary">Crear Usuario</Button>
			</Form>
		</React.Fragment>
	)
}