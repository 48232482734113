import React from 'react'
import { Table } from 'antd'

import Pagination from '../../../components/Pagination'
import moment from 'moment'

export default function SurveyResponsesTable({ surveyResponses, reload, pagination, setPagination }) {

	const columns = [
		{
			title: 'Nombre de Encuesta',
			dataIndex: 'survey',
			render: t => t.name
		},
		{
			title: 'Respondida por',
			dataIndex: 'user',
			render: t => t.name
		},
		{
			title: 'Recibida el',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MMM-DD hh:mm A')
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={surveyResponses}
				rowKey={record => record.id}
				columns={columns}
				pagination={false}
				expandable={{
					expandedRowRender: record => (
						<div>
							{ record.answers.map( answer => (
								<p key={answer.id}>
									<strong>{answer.question.name}:{" "}
									</strong>{answer.value}
								</p>
							)) }
						</div>
					)
				}}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
		</React.Fragment>
	)
}