import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchStores } from '../../Stores/services'

export default function UserForm({ user, register, errors, selectedStore, setSelectedStore }) {
	return (
		<React.Fragment>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Código de Vendedor <span className="text-danger">*</span></Form.Label>
					<Form.Control {...register('code', {required:true})} defaultValue={user && user.code} />
					<Form.Text className={errors.code ? `text-warning` : 'text-hint'}>
						{ errors.code ? 'Este campo es requerido.' : 'En caso de no tener ingrese la cédula' }
					</Form.Text>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Documento Identidad <span className="text-danger">*</span></Form.Label>
					<Form.Control {...register('document', {required:true})} defaultValue={user && user.document} />
					{ errors.document && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Nombre Completo <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', {required:true})} defaultValue={user && user.name} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Correo Electrónico <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('email', {required:true})} defaultValue={user && user.email} />
				{ errors.email && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			{ !user ? (
				<Form.Group>
					<Form.Label>Contraseña</Form.Label>
					<Form.Control {...register("password")} placeholder="Últimos 4 dígitos de la cédula" disabled />
				</Form.Group>
			) : (
				<Form.Group>
					<Form.Label>Estado</Form.Label>
					<Form.Control as="select" {...register('active',{required:true})} defaultValue={user && user.active}>
						<option value="1">Activo</option>
						<option value="0">Inactivo</option>
					</Form.Control>
				</Form.Group>
			)}
			{selectedStore && (
				<Form.Group>
					<Form.Label>Tienda Principal <span className="text-danger">*</span></Form.Label>
					<ServerSideSelect 
						value={selectedStore}
						placeholder="Seleccione una tienda"
						fetchOptions={searchStores}
						onChange={value => setSelectedStore(value)}
						className="full-width"
					/>
				</Form.Group>
			)}
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Cargo</Form.Label>
					<Form.Control
						as="select"
						{...register('ranking', { required:true })}
						defaultValue={user && user.ranking}
					>
						<option value="agent">Asesor Comercial</option>
						<option value="store_leader">Líder de Tienda</option>
						<option value="coordinador">Coordinador</option>
						<option value="jefe_regional">Jefe Regional</option>
						<option value="trade">Trade</option>
						<option value="kam">KAM</option>
						<option value="director">Director</option>
						<option value="admin">Administrador</option>
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Permisos</Form.Label>
					<Form.Control as="select" {...register('role',{required:true})} defaultValue={user && user.role}>
						<option value="agent">Solo ver sus datos</option>
						<option value="admin">Ver otros usuarios de la tienda</option>
					</Form.Control>
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Ingreso de Tarifas Personalizadas</Form.Label>
				<Form.Control as="select" {...register('can_add_rates',{required:true})} defaultValue={user ? user.can_add_rates : "0"}>
					<option value="0">Inactivo</option>
					<option value="1">Activo</option>
				</Form.Control>
			</Form.Group>
		</React.Fragment>
	)
}