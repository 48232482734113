import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import { Statistic } from 'antd'
import moment from 'moment'

import VisitsReportTopBar from './partials/VisitsReportTopBar'
import VisitsTable from '../Visits/partials/VisitsTable'

import { getVisitsReport } from './services'
import { handleError, round } from '../../helpers'

export default function VisitsGeneralReport() {
	const user = useSelector(state => state.auth.user)
	const [report, setReport] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 10, total: 0})
	const [params, setParams] = useState({
		from: moment().startOf('month'),
		to: moment().endOf('month'),
		user_id: user.id,
		user_name: user.name,
	})

	useEffect(() => {
		!report && getVisitsReport({
			...pagination,
			...params,
			from: params.from.format('YYYY-MM-DD') + ' 00:00:00',
			to: params.to.format('YYYY-MM-DD') + ' 23:59:59',
		})
			.then(res => {
				setReport(res.data.data)
				setPagination({
					current_page: res.data.data.visits.current_page, 
					per_page: res.data.data.visits.per_page, 
					total: res.data.data.visits.total
				})
			})
			.catch(error => handleError(error))
	}, [ report, params, pagination ])

	const statCards = report ? [
		{
			title: "Visitas Creadas",
			value: report.scheduled_count,
			suffix: "agendadas"
		},
		{
			title: "Visitas Completadas",
			value: report.completed_count,
			suffix: report.completed_count ? `${round(report.completed_count/report.scheduled_count*100)}% completadas` : 'completadas'
		},
		{
			title: "Visitas Canceladas",
			value: report.cancelled_count,
			suffix: report.cancelled_count ? `${round(report.cancelled_count/report.scheduled_count*100)}% canceladas` : 'canceladas'
		},
	] : []

	return (
		<React.Fragment>
			<VisitsReportTopBar 
				params={params}
				setParams={setParams}
				reload={() => setReport(null)}
			/>
			<Row>
				<Col xs="6" md="3">
					<Card>
						<CardBody>
							{ report && (
								<Statistic 
									title={report.params.user_name} 
									suffix={`${moment(report.params.from).format('DD MMM YY')} al ${moment(report.params.to).format('DD MMM YY')}`}
									value="Visitas"
								/>
							)}
						</CardBody>
					</Card>
				</Col>
				{ report ? statCards.map( (stat,i) => (
					<Col xs="6" md="3" key={i}>
						<Card>
							<CardBody>
								<Statistic {...stat} />
							</CardBody>
						</Card>
					</Col>
				)) : (
					<Col className="text-center mt-2 mb-4">
						<Spinner size="sm" />
					</Col>
				)}
			</Row>
			<Card>
				<CardBody>
					{ report ? (
						<VisitsTable 
							visits={report.visits.data}
							reload={() => setReport(null)}
							pagination={pagination}
							setPagination={setPagination}
						/>
					) : <Spinner />}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}