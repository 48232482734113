import React from 'react'
import { Card, CardHeader, CardTitle, CardSubtitle, Button } from 'reactstrap'
import { DatePicker } from 'antd'
import moment from 'moment'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchUsers } from '../../Users/services'
const { RangePicker } = DatePicker

export default function VisitsReportTopBar({ params, setParams, reload }) {
	return (
		<React.Fragment>
			<Card>
				<CardHeader>
					<div className="float-right pt-2">
						<ServerSideSelect
							className="search-top-bar mr-2 pt-1"
							value={{ 
								label: params.user_name,
								value: params.user_id	
							}}
							fetchOptions={searchUsers}
							onChange={res => setParams({ 
								...params, 
								user_id: res.value, 
								user_name: res.label
							})}
						/>
						<RangePicker
							ranges={{
								'Hoy': [moment(), moment()],
								'Esta semana': [moment().startOf('week'), moment().endOf('week')],
								'Este mes': [moment().startOf('month'), moment().endOf('month')],
								'Mes pasado': [moment().subtract(1,'months').startOf('month'), moment().subtract(1,'months').endOf('month')],
								'Este año': [moment().startOf('year'), moment().endOf('year')],
							}}
							placeholder={["F. de Inicio", "F. Final"]}
							value={[params.from, params.to]}
							onChange={ values => { 
								if(values.length > 0)
									setParams({ ...params, from: values[0], to: values[1] })
							}}
							className="mr-3"
						/>
						<Button color="primary" onClick={reload}>Generar Reporte</Button>
					</div>
					<CardTitle>Reporte de visitas por asesor</CardTitle>
					<CardSubtitle>Cumplimiento y gestión de rutas</CardSubtitle>
				</CardHeader>
			</Card>
		</React.Fragment>
	)
}