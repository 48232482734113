import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import CompetitorReferenceForm from './CompetitorReferenceForm'
import { updateCompetitorReference } from '../services'
import { handleError } from '../../../helpers'

export default function EditCompetitorReferenceModal({ visible, onClose, competitorReference, reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		if(values.code === competitorReference.code) delete values.code
		updateCompetitorReference(competitorReference.id, values)
			.then(() => {
				message.success('Referencia actualizada exitosamente.')
				reset()
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar referencias</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<CompetitorReferenceForm 
							competitorReference={competitorReference} 
							register={register} 
							errors={errors} 
						/>
						<Button type="submit" color="primary">Actualizar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}