import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import QuestionsList from '../../Questions/QuestionsList'

export default function QuestionsModal({ visible, onClose, survey }) {

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="lg">
				<ModalHeader toggle={onClose}>Preguntas Asociadas</ModalHeader>
				<ModalBody>
					<QuestionsList survey={survey} />
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}