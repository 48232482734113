import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import VisitsList from './VisitsList'

export default function Visits() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/visits" component={VisitsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}