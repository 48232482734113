import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import { updateUser } from '../services'
import { handleError } from '../../../helpers'
import UserForm from './UserForm'

export default function EditUserModal({ visible, onClose, user, reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		if(parseInt(values.document) === parseInt(user.document)) delete values.document
		if(parseInt(values.code) === parseInt(user.code)) delete values.code
		if(values.email === user.email) delete values.email
		updateUser(user.id, values)
			.then(() => {
				message.success('Usuario actualizado exitosamente.')
				reset()
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	const handleClose = () => {
		reset()
		onClose()
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={handleClose}>
				<ModalHeader toggle={handleClose}>Actualizar Usuario</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<UserForm 
							user={user} 
							register={register} 
							errors={errors}
						/>
						<Button type="submit" color="primary">Actualizar Usuario</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}