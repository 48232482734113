import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faUsers, faStore, faLockOpen, faBell, faCogs } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function SettingsDashboard() {
	return (
		<React.Fragment>
			<PageTitle title="Ajustes Generales" subtitle="Administrar usuarios, tiendas y permisos." />
			<Row>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Notificaciones"
						subtitle="Consultar, crear y actualizar notificaciones"
						icon={faBell}
						linkTo={"/notifications"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3 hide">
					<MenuWidget 
						title="Permisos de Usuarios"
						subtitle="Administrar permisos en App y Admin"
						icon={faLockOpen}
						linkTo={"/users/permissions"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget
						title="Categorías de Novedades"
						subtitle="Crear, editar y eliminar categorías de novedades"
						icon={faCogs}
						linkTo={"/ticket_categories"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Directorio de Usuarios"
						subtitle="Consultar, crear y actualizar usuarios de la plataforma"
						icon={faUsers}
						linkTo={"/users"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Directorio de Tiendas"
						subtitle="Consultar, crear y actualizar listado de tiendas"
						icon={faStore}
						linkTo={"/stores"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}