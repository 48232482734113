import springApi from '../../services/springApi'

export const getMetricResponses = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/metric_responses', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeMetricResponse = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/metric_responses', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateMetricResponse = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/metric_responses/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteMetricResponse = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/metric_responses/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)