import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'
import { handleError } from '../../helpers'

import NewMetricListForm from './partials/NewMetricListForm'
import MetricListsTable from './partials/MetricListsTable'
import { getMetricLists } from './services'

export default function MetricListsList() {
	const [metricLists, setMetricLists] = useState(null)

	useEffect(()=>{
		!metricLists && getMetricLists({
			include: 'metric_templates_count'
		})
			.then(res => setMetricLists(res.data.data))
			.catch(error => handleError(error))
	}, [metricLists])

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Listado de Metricas</CardTitle>
							<CardSubtitle>Ingrese los datos del nuevo listado</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewMetricListForm reload={()=>setMetricLists(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<Card>
						<CardHeader>
							<CardTitle>Listados de Metricas Creados</CardTitle>
							<CardSubtitle>Administre los listados de metricas existentes</CardSubtitle>
						</CardHeader>
						<CardBody>
							<MetricListsTable metricLists={metricLists} reload={()=>setMetricLists(null)} />
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</React.Fragment>
	)
}