import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import MetricTemplatesList from '../../MetricTemplates/MetricTemplatesList'

export default function MetricTemplatesModal({ visible, onClose, metricList }) {

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="lg">
				<ModalHeader toggle={onClose}>Métricas Asociadas</ModalHeader>
				<ModalBody>
					<MetricTemplatesList 
						metricListId={metricList && metricList.id}
					/>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}