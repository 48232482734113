import springApi from '../../services/springApi'

export const getPromoDetails = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/promo_details', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storePromoDetail = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/promo_details', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updatePromoDetail = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/promo_details/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deletePromoDetail = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/promo_details/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)