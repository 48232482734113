import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import StoreForm from './StoreForm'
import { updateStore } from '../services'
import { handleError } from '../../../helpers'

export default function EditStoreModal({ visible, onClose, store, reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		if(values.code === store.code) delete values.code
		updateStore(store.id, values)
			.then(() => {
				message.success('Tienda actualizada exitosamente.')
				reload()
				reset()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Tienda</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<StoreForm store={store} register={register} errors={errors} />
						<Button type="submit" color="primary">Actualizar Tienda</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}