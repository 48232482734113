import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { faComments, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'

import Pagination from '../../../components/Pagination'
import CommentsModal from '../../Comments/partials/CommentsModal'

import { deleteTicket, updateTicket } from '../services'
import { handleError } from '../../../helpers'

export default function TicketsTable({ tickets, reload, pagination, setPagination }) {
	const user = useSelector(state => state.auth.user)
	const [commentsModal, setCommentsModal] = useState(null)

	const handleDelete = id => {
		deleteTicket(id)
			.then(() => {
				message.success('Novedad eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleNextStatus = ticket => {
		updateTicket(ticket.id, {
			status: ticket.next_status.value
		})
			.then(() => {
				message.success('Estado actualizado exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Asunto',
			dataIndex: 'subject'
		},
		{
			title: 'Categoría',
			dataIndex: 'ticket_category',
			render: t => t.name
		},
		{
			title: 'Usuario',
			dataIndex: 'user',
			render: t => t.name
		},
		{
			title: 'Tienda',
			dataIndex: 'store',
			render: t => t.name
		},
		{
			title: 'Estado',
			dataIndex: 'status_name'
		},
		{
			title: 'Siguiente Estado',
			render: (t, record) => (
				<Popconfirm
					title={`Cambiar estado a: ${record.next_status.label}`}
					okText="Cambiar"
					cancelText="Cancelar"
					onConfirm={() => handleNextStatus(record)}
				>
					<Button color="primary" outline style={{ fontSize: 14 }}>
						{`Marcar como ${record.next_status.label}`}
					</Button>
				</Popconfirm>
			)
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Comentarios">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faComments} 
							onClick={() => setCommentsModal(record)} 
						/>
					</Tooltip>
					<Divider type="vertical" />
					{ user.ranking === 'admin' && <Popconfirm 
						title="¿Desea eliminar la inicidencia?"
						okText="Eliminar"
						cancelText="Cancelar"
						onConfirm={() => handleDelete(record.id)}
						okButtonProps={{ danger:true }}
					>
						<FontAwesomeIcon className="text-link" icon={faTrash} />
					</Popconfirm> }
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={tickets}
				rowKey={record => record.id}
				columns={columns}
				pagination={false}
				expandable={{ 
					expandedRowRender: record => (
						<React.Fragment>
							<p><strong>Mensaje: </strong>{ record.description }</p>
							<p>
								<strong>Zendesk ID: </strong>
								{ record.zendesk_key ? record.zendesk_key : 'Sin registro' }
							</p>
						</React.Fragment>
					)
				}}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			<CommentsModal 
				visible={commentsModal ? true : false}
				onClose={() => setCommentsModal(null)}
				modelType="App\Models\Ticket"
				modelId={commentsModal && commentsModal.id}
				comments={commentsModal && commentsModal.comments}
				reload={reload}
			/>
		</React.Fragment>
	)
}