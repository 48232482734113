import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { Form } from 'react-bootstrap'

import VisitsTable from './partials/VisitsTable'
import NewVisitModal from './partials/NewVisitModal'
import ListTopBar from '../../components/ListTopBar'
import ServerSideSelect from '../../components/ServerSideSelect'

import { getVisits } from './services'
import { searchUserStores } from '../Stores/services'
import { handleError, parsePagination } from '../../helpers'
import ExportVisitsModal from './partials/ExportVisitsModal'

export default function VisitsList() {
	const user = useSelector(state => state.auth.user)
	const [visits, setVisits] = useState(null)
	const [filterType, setFilterType] = useState('user.name')
	const [filterValue, setFilterValue] = useState('')
	const [showNewModal, setShowNewModal] = useState(false)
	const [showExportModal, setShowExportModal] = useState(false)
	const [scope, setScope] = useState('all')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [filterStore, setFilterStore] = useState({ label: user.current_store_name, value: user.current_store })

	useEffect(() => {
		!visits && getVisits({
			...pagination,
			'filter[store_id]': filterStore.value,
			[`filter[${filterType}]`]: filterValue,
			include: 'store,user',
			sort: '-schedule_at',
			[`filter[${scope}]`]: 1,
		})
			.then(res => {
				setVisits(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ visits, pagination, filterType, filterValue, filterStore, scope ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setVisits(null)}
				title="Agenda de Visitas"
				subtitle="Listados de visitas registradas desde app"
				options={[
					{ label: 'Nombre del asesor', value: 'user.name' },
					{ label: 'Fecha de visita', value: 'schedule_at' },
					{ label: 'Código de tienda', value: 'store.code' },
					{ label: 'Nombre de tienda', value: 'store.name' },
				]}
				ctaText="Registrar Visita"
				handleCtaClick={() => setShowNewModal(true)}
				prefix={(
					<Form.Control 
						as="select"
						value={scope}
						onChange={e => setScope(e.target.value)}
					>
						<option value="all">Ver todos los estados</option>
						<option value="active">Ver solo activas</option>
						<option value="expired">Ver solo vencidas</option>
						<option value="canceled">Ver solo canceladas</option>
						<option value="completed">Ver solo completadas</option>
					</Form.Control>
				)}
				onExport={() => setShowExportModal(true)}
			/>
			<Card>
				<CardBody>
					<ServerSideSelect
						value={filterStore}
						fetchOptions={searchUserStores}
						onChange={value => {
							setFilterStore(value)
							setVisits(null)
						}}
						userId={user.id}
						className="search-top-bar"
					/>
					<VisitsTable 
						visits={visits} 
						reload={()=>setVisits(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			<NewVisitModal 
				visible={showNewModal}
				onClose={() => setShowNewModal(false)}
				reload={() => setVisits(null)}
			/>
			{ showExportModal && (
				<ExportVisitsModal 
					visible
					onClose={() => setShowExportModal(false)}
				/>
			)}
		</React.Fragment>
	)
}