import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody} from 'reactstrap'

import StoresTable from './partials/StoresTable'
import NewStoreModal from './partials/NewStoreModal'
import ListTopBar from '../../components/ListTopBar'

import { getStores } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function StoresList() {
	const userId = useSelector(state => state.auth.user.id)
	const [stores, setStores] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [showNewModal, setShowNewModal] = useState(false)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!stores && getStores({
			...pagination,
			'filter[related_to_user]': userId,
			[`filter[${filterType}]`]: filterValue,
			sort: '-created_at',
		})
			.then(res => {
				setStores(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ stores, pagination, filterType, filterValue, userId ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setStores(null)}
				title="Directorio de Tiendas"
				subtitle="Listado de tiendas registradas en la plataforma"
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por código', value: 'code' },
					{ label: 'Buscar por regional', value: 'region' },
					{ label: 'Buscar por ciudad', value: 'city' },
					{ label: 'Buscar por categoría', value: 'type' },
				]}
				ctaText="Crear Tienda"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<StoresTable
						stores={stores} 
						reload={()=>setStores(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			<NewStoreModal 
				visible={showNewModal}
				onClose={() => setShowNewModal(false)}
				reload={() => setStores(null)}
			/>
		</React.Fragment>
	)
}