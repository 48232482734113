import springApi from '../../services/springApi'

export const getUserSchedules = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/user_schedules', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeUserSchedule = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/user_schedules', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateUserSchedule = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/user_schedules/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteUserSchedule = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/user_schedules/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)