import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import CompetitorsList from './CompetitorsList'

export default function Competitors() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/competitors" component={CompetitorsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}