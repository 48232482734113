import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'

import NewCompetitorReferenceForm from './partials/NewCompetitorReferenceForm'
import CompetitorReferencesTable from './partials/CompetitorReferencesTable'
import ListTopBar from '../../components/ListTopBar'

import { getCompetitorReferences } from './services'
import { handleError } from '../../helpers'

export default function CompetitorReferencesList() {
	const [competitorReferences, setCompetitorReferences] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(()=>{
		!competitorReferences && getCompetitorReferences({
			include: 'competitor',
			[`filter[${filterType}]`]: filterValue
		})
			.then(res => setCompetitorReferences(res.data.data))
			.catch(error => handleError(error))
	}, [competitorReferences, filterType, filterValue])

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Referencia</CardTitle>
							<CardSubtitle>Ingrese los datos de la referencia</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewCompetitorReferenceForm 
								reload={()=>setCompetitorReferences(null)} 
							/>
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<ListTopBar 
						filterType={filterType}
						setFilterType={setFilterType}
						filterValue={filterValue}
						setFilterValue={setFilterValue}
						title="Referencias de Competidores"
						subtitle="Listado de referencias en el sistema"
						reload={() => setCompetitorReferences(null)}
						options={[
							{ label: 'Buscar por referencia', value: 'name' },
							{ label: 'Buscar por competidor', value: 'competitor.name' },
						]}
					/>
					<Card>
						<CardBody>
							<CompetitorReferencesTable 
								competitorReferences={competitorReferences} 
								reload={()=>setCompetitorReferences(null)} 
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</React.Fragment>
	)
}