import React, { useState, useEffect } from 'react'
import { Card, CardBody} from 'reactstrap'

import CompetitorRatesTable from './partials/CompetitorRatesTable'
import ListTopBar from '../../components/ListTopBar'

import { getCompetitorRates } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function CompetitorRatesList() {
	const [competitorRates, setCompetitorRates] = useState(null)
	const [filterType, setFilterType] = useState('competitor.name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!competitorRates && getCompetitorRates({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'competitor,user,competitor_reference',
			sort: '-created_at'
		})
			.then(res => {
				setCompetitorRates(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ competitorRates, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setCompetitorRates(null)}
				title="Tarifas y Promociones de la Competencia"
				subtitle="Listados de tarifas registradas desde app"
				options={[
					{ label: 'Buscar por nombre del competidor', value: 'competitor.name' },
					{ label: 'Buscar por nombre del asesor', value: 'user.name' },
					{ label: 'Buscar por nombre del canal', value: 'channel' },
				]}
			/>
			<Card>
				<CardBody>
					<CompetitorRatesTable 
						competitorRates={competitorRates} 
						reload={()=>setCompetitorRates(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}