import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import TaskListForm from './TaskListForm'
import { updateTaskList } from '../services'
import { handleError } from '../../../helpers'

export default function EditTaskListModal({ visible, onClose, taskList, reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		if(values.code === taskList.code) delete values.code
		updateTaskList(taskList.id, values)
			.then(() => {
				message.success('Listado de Tareas actualizado exitosamente.')
				reload()
				reset()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Listado de Tareas</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<TaskListForm taskList={taskList} register={register} errors={errors} />
						<Button type="submit" color="primary">Actualizar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}