import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faArchive, faTasks, faChartArea } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function TasksDashboard() {
	return (
		<React.Fragment>
			<PageTitle title="Gestión de Tareas" subtitle="Listados de tareas, plantillas y respuestas registradas." />
			<Row>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Listados de Tareas"
						subtitle="Crear, editar y eliminar listados o ckecklists de tareas"
						icon={faArchive}
						linkTo={"/task_lists"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Respuestas de Listados"
						subtitle="Consultar ckecklists de tareas respondidos por usuarios"
						icon={faTasks}
						linkTo={"/task_responses"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3 hide">
					<MenuWidget 
						title="Reporte de Tareas"
						subtitle="Reporte de cumplimiento y gestión de tareas"
						icon={faChartArea}
						linkTo={"/reports/tasks"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}