import springApi, { getDomain } from '../../services/springApi'

export const getStores = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/stores', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeStore = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/stores', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateStore = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/stores/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteStore = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/stores/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchStores = async (name, token) => (
	fetch( getDomain()+`/stores?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(store => ({
				label: `(${store.code}) ${store.name}`,
				value: store.id,
			})),
		)
)

export const searchUserStores = async (name, token, userId) => (
	fetch( getDomain()+`/stores?filter[related_to_user]=${userId}&filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(store => ({
				label: store.name,
				value: store.id,
			})),
		)
)

export const getModelStores = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/model_stores', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeModelStore = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/model_stores', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteModelStore = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/model_stores/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getStoreUsers = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/store_users', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeStoreUser = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/store_users', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteStoreUser = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/store_users/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)