import moment from 'moment'
import React from 'react'
import { Form } from 'react-bootstrap'

export default function PromoForm({ promo, register, errors }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre <span className="text-danger">*</span></Form.Label>
				<Form.Control 
					{...register("name", {required: true})} 
					defaultValue={promo && promo.name} 
				/>
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Marca <span className="text-danger">*</span></Form.Label>
				<Form.Control as="select" {...register('brand',{required:true})} defaultValue={promo && promo.brand}>
					<option>Spring</option>
					<option>Tempur</option>
					<option>Marcas Aliadas</option>
				</Form.Control>
			</Form.Group>
			<Form.Group>
				<Form.Label>Fecha de Inicio <span className="text-danger">*</span></Form.Label>
				<Form.Control 
					{...register("start_at", {required: true})} 
					defaultValue={promo && moment(promo.start_at).format('YYYY-MM-DD')} 
					type="date"
				/>
				{ errors.start_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Válida Hasta <span className="text-danger">*</span></Form.Label>
				<Form.Control 
					{...register("end_at", {required: true})} 
					defaultValue={promo && moment(promo.end_at).format('YYYY-MM-DD')} 
					type="date"
				/>
				{ errors.start_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Estado <span className="text-danger">*</span></Form.Label>
				<Form.Control as="select" {...register('active',{required:true})} defaultValue={promo && promo.active}>
					<option value="1">Activa</option>
					<option value="0">Inactiva</option>
				</Form.Control>
			</Form.Group>
		</React.Fragment>
	)
}