import React from 'react'
import { Form } from 'react-bootstrap'

export default function TaskListForm({ taskList, register, errors }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre del Listado de Tareas <span className="text-danger">*</span></Form.Label>
				<Form.Control 
					{...register("name", {required: true})} 
					defaultValue={taskList && taskList.name} 
				/>
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			{ !taskList && (
				<Form.Group>
					<Form.Label>Visible para <span className="text-danger">*</span></Form.Label>
					<Form.Check 
						label="Asesores"
						value="agent"
						{...register("allowed_rankings", {required: true})}
					/>
					<Form.Check 
						label="Lideres de Tienda"
						value="store_leader"
						{...register("allowed_rankings", {required: true})}
					/>
					<Form.Check 
						label="Coordinadores"
						value="coordinador"
						{...register("allowed_rankings", {required: true})}
					/>
					<Form.Check 
						label="Jefes Regionales"
						value="jefe_regional"
						{...register("allowed_rankings", {required: true})}
					/>
					<Form.Check 
						label="Trades"
						value="trade"
						{...register("allowed_rankings", {required: true})}
					/>
					<Form.Check 
						label="KAMs"
						value="kam"
						{...register("allowed_rankings", {required: true})}
					/>
					<Form.Check 
						label="Directores de Unidad de Negocio"
						value="director"
						{...register("allowed_rankings", {required: true})}
					/>

					{ errors.allowed_rankings && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			)}
			<Form.Group>
				<Form.Label>Estado</Form.Label>
				<Form.Control as="select" {...register('active',{required:true})} defaultValue={taskList && taskList.active}>
					<option value="0">Inactivo</option>
					<option value="1">Activo</option>
				</Form.Control>
			</Form.Group>
		</React.Fragment>
	)
}