import springApi from '../../services/springApi'

export const getCompetitorReferences = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/competitor_references', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCompetitorReference = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/competitor_references', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCompetitorReference = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/competitor_references/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCompetitorReference = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/competitor_references/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)