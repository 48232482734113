import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import PromoDetailsList from '../../PromoDetails/PromoDetailsList'

export default function PromoDetailsModal({ visible, onClose, promo }) {	
	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="lg">
				<ModalHeader toggle={onClose}>Detalles de la Dinámica</ModalHeader>
				<ModalBody>
					<PromoDetailsList promoId={promo && promo.id} />
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}