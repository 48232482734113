import React from 'react'
import { useSelector } from 'react-redux'
import { message, Popconfirm, Table } from 'antd'

import Pagination from '../../../components/Pagination'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { deleteTaskResponse } from '../services'
import { handleError } from '../../../helpers'

export default function TaskResponsesTable({ taskResponses, reload, pagination, setPagination }) {
	const user = useSelector(state => state.auth.user)

	const handleDelete = id => {
		deleteTaskResponse(id)
			.then(() => {
				message.success('Listado elimiando exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Nombre del Checklist',
			dataIndex: 'task_list',
			render: t => t.name
		},
		{
			title: 'Registrado por',
			dataIndex: 'user',
			render: t => t.name
		},
		{
			title: 'Registrado el',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MMM-DD hh:mm A')
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					{ user.ranking === 'admin' && <Popconfirm
						title="¿Seguro desea eliminarla?"
						okText="Eliminar"
						cancelText="Cancelar"
						onConfirm={() => handleDelete(record.id)}
						okButtonProps={{ danger: true }}
					>
						<FontAwesomeIcon icon={faTrash} color="primary" />
					</Popconfirm> }
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={taskResponses}
				rowKey={record => record.id}
				columns={columns}
				pagination={false}
				expandable={{ 
					expandedRowRender: record => (
						<React.Fragment>
							{ record.tasks.map(task => (
								<p key={task.id}>
									<strong>{task.task_template.name}: </strong>
									{task.comment}
								</p>
							)) }
						</React.Fragment>
					)
				}}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
		</React.Fragment>
	)
}