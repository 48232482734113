import springApi from '../../services/springApi'

export const getTicketCategories = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/ticket_categories', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeTicketCategory = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/ticket_categories', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateTicketCategory = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/ticket_categories/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteTicketCategory = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/ticket_categories/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)