import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import { updateMetricTemplate } from '../services'
import { handleError } from '../../../helpers'
import MetricTemplateForm from './MetricTemplateForm'

export default function EditMetricTemplateModal({ visible, onClose, metricTemplate, reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		updateMetricTemplate(metricTemplate.id, values)
			.then(() => {
				message.success('Métrica actualizada exitosamente.')
				reload()
				reset()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Plantilla de Métricas</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<MetricTemplateForm metricTemplate={metricTemplate} register={register} errors={errors} />
						<Button type="submit" color="primary">Actualizar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}