import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { Form } from 'react-bootstrap'

import ListTopBar from '../../components/ListTopBar'
import TicketsTable from './partials/TicketsTable'
import { getTickets } from './services'
import { handleError, parsePagination } from '../../helpers'
import ServerSideSelect from '../../components/ServerSideSelect'
import { searchUserStores } from '../Stores/services'

export default function TicketsList() {
	const user = useSelector(state => state.auth.user)
	const [tickets, setTickets] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [filterType, setFilterType] = useState('subject')
	const [filterValue, setFilterValue] = useState('')
	const [status, setStatus] = useState('')
	const [filterStore, setFilterStore] = useState({ label: user.current_store_name, value: user.current_store })

	useEffect(()=>{
		!tickets && getTickets({
			...pagination,
			'filter[store_id]': filterStore.value,
			[`filter[${filterType}]`]: filterValue,
			include: 'ticketCategory,user,comments,store',
			'filter[status]': status
		})
			.then(res => {
				setTickets(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [tickets, pagination, filterType, filterValue, filterStore, status])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setTickets(null)}
				title="Listado de Novedades"
				subtitle="Incidencias reportadas desde el aplicativo"
				options={[
					{ label: 'Asunto del Ticket', value: 'subject' },
					{ label: 'Asunto del Cateogría', value: 'ticket_category.name' },
				]}
				prefix={(
					<Form.Control 
						as="select"
						value={status}
						onChange={e => setStatus(e.target.value)}
					>
						<option value="">Ver todos los estados</option>
						<option value="pendding">Ver solo en progreso</option>
						<option value="active">Ver solo activas</option>
						<option value="closed">Ver solo cerradas</option>
					</Form.Control>
				)}
			/>
			<Card>
				<CardBody>
					<ServerSideSelect
						value={filterStore}
						fetchOptions={searchUserStores}
						onChange={value => {
							setFilterStore(value)
							setTickets(null)
						}}
						userId={user.id}
						className="search-top-bar"
					/>
					<TicketsTable 
						tickets={tickets} 
						reload={()=>setTickets(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}