import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'
import { Col, Row } from 'react-bootstrap'
import moment from 'moment'

import Pagination from '../../../components/Pagination'
import EditCompetitorRateModal from './EditCompetitorRateModal'

import { deleteCompetitorRate, updateCompetitorRate } from '../services'
import { handleError, currency, round } from '../../../helpers'

export default function CompetitorRatesTable({ competitorRates, reload, pagination, setPagination }) {
	const user = useSelector(state => state.auth.user)
	const [editCompetitorRate, setEditCompetitorRate] = useState(null)

	const handleDelete = id => {
		deleteCompetitorRate(id)
			.then(() => {
				message.success('Tarifa eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleRegister = id => {
		updateCompetitorRate(id, {
			register_at: moment().format('YYYY-MM-DD HH:mm:ss')
		})
			.then(() => {
				message.success('Tarifa registrada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Competidor',
			dataIndex: 'competitor',
			render: t => t.name
		},
		{
			title: 'Referencia',
			dataIndex: 'competitor_reference',
			render: t => t.name
		},
		{
			title: 'Asesor que reporta',
			dataIndex: 'user',
			render: t => t.name
		},
		{
			title: 'Reportado el',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MMM-DD')
		},
		{
			title: 'Registrado el',
			dataIndex: 'register_at',
			render: (t, record) => t ? (
				moment(t).format('YYYY-MMM-DD HH:mm') 
			): (
				<Button 
					color="primary" 
					outline
					onClick={() => handleRegister(record.id)}
					style={{ fontSize: 13 }}
				>
					Marcar como Registrado
				</Button>
			)
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					{/* <Tooltip title="Editar">
						<FontAwesomeIcon 
							icon={faEdit} 
							className="text-link"
							onClick={() => setEditCompetitorRate(record)}
						/>
					</Tooltip> */}
					{ record.image && (
						<React.Fragment>
						<Divider type="vertical" />
						<Tooltip title="Imagen">
							<a href={record.image} target="_blank" rel="noreferrer">
								<FontAwesomeIcon
									icon={faImage} 
									className="text-link"
								/>
							</a>
						</Tooltip>
						</React.Fragment>
					)}
					{ user.ranking === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm
								title="¿Segura desea proceder?"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDelete(record.id)}
							>
								<FontAwesomeIcon icon={faTrash} className="text-link" />
							</Popconfirm>
						</React.Fragment>
					)}					
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				columns={columns}
				dataSource={competitorRates}
				rowKey={record => record.id}
				locale={{ emptyText: 'No se encontraron tarifas' }}
				expandable={{ 
					expandedRowRender: record => (
						<React.Fragment>
							<Row>
								<Col>
									<p className="mb-1"><strong>Tarifas PVP Full:</strong> ${currency(record.pvp_full)}</p>
									<p className="mb-1"><strong>Tarifas PVP con Descuento:</strong> ${currency(record.pvp_dcto)}</p>
									<p className="mb-1"><strong>Descuento:</strong> {round(record.pvp_dcto/record.pvp_full*100)}%</p>
									<p className="mb-1"><strong>Tarifas PVP Colchon + Base Cama:</strong> {record.pvp_combo ? `$${currency(record.pvp_combo)}` : <em>Sin registro</em>}</p>
								</Col>
								<Col>
									<p className="mb-1"><strong>Promesa de Entrega:</strong> { record.delivery ? record.delivery : <em>Sin registro</em> }</p>
									<p className="mb-1"><strong>Dinámica Comercial:</strong> { record.promo ? record.promo : <em>Sin registro</em> }</p>
									<p className="mb-1"><strong>Observación:</strong> { record.comment ? record.comment : <em>Sin registro</em> }</p>
								</Col>
							</Row>							
						</React.Fragment>
					)
				}}
				pagination={false}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			<EditCompetitorRateModal 
				visible={editCompetitorRate ? true : false}
				onClose={() => setEditCompetitorRate(null)}
				reload={reload}
				competitorRate={editCompetitorRate}
			/>
		</React.Fragment>
	)
}