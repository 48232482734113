import springApi from '../../services/springApi'

export const getTaskResponses = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/task_responses', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeTaskResponse = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/task_responses', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateTaskResponse = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/task_responses/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteTaskResponse = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/task_responses/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)