import React, { useState } from 'react'
import { message } from 'antd'
import { Button, InputGroup } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { handleError } from '../../../helpers'
import { storeStoreUser } from '../../Stores/services'
import { searchUsers } from '../../Users/services'

export default function StoreUserForm({ storeId, reload }) {
	const [selectedUser, setSelectedUser] = useState([])

	const onSubmit = () => {
		if(!selectedUser) 
			message.warn('Debe seleccionar un usuario')

		selectedUser && storeStoreUser({
			user_id: selectedUser.value,
			store_id: storeId
		})
			.then(() => {
				message.success('Tienda asociada exitosamente')
				setSelectedUser([])
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<InputGroup className="mt-2">
				<ServerSideSelect
					value={selectedUser}
					placeholder="Seleccione un usuario"
					fetchOptions={searchUsers}
					onChange={value => setSelectedUser(value)}
					style={{ flex: 1 }}
				/>
				<InputGroup.Append>
					<Button 
						variant="primary" 
						onClick={onSubmit}
						style={{ height: '32px', padding: '0px 5px' }}
					>
						Asociar Usuario
					</Button>
				</InputGroup.Append>
			</InputGroup>
		</React.Fragment>
	)
}