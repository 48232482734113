import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import TaskListForm from './TaskListForm'
import { storeTaskList } from '../services'
import { handleError } from '../../../helpers'

export default function NewTaskListForm({ reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm();

	const onSubmit = values => {
		values.allowed_rankings = values.allowed_rankings.join(',')
		values.allowed_rankings = values.allowed_rankings + ',admin'
		storeTaskList(values)
			.then(() => {
				message.success('Listado creado exitosamente.')
				reload()
				reset()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<TaskListForm register={register} errors={errors} />
				<Button type="submit" color="primary">Crear Listado de Tareas</Button>
			</Form>
		</React.Fragment>
	)
}