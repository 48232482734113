import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faChartBar, faCogs, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function TicketsDashboard() {
	return (
		<React.Fragment>
			<PageTitle
				title="Control de Novedades"
				subtitle="Listado de novedades reportadas y administración de categorías de incidncias."
			/>
			<Row>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget
						title="Listados de Novedades"
						subtitle="Consultar y gestionar novedades reportadas"
						icon={faQuestionCircle}
						linkTo={"/tickets"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget
						title="Categorías de Novedades"
						subtitle="Crear, editar y eliminar categorías de novedades"
						icon={faCogs}
						linkTo={"/ticket_categories"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3 hide">
					<MenuWidget 
						title="Reporte de Novedades"
						subtitle="Reporte general del control de novedades"
						icon={faChartBar}
						linkTo={"/reports/tickets"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}