import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import SurveyResponsesList from './SurveyResponsesList'

export default function SurveyResponses() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/survey_responses" component={SurveyResponsesList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}