import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import CustomRatesTable from './partials/CustomRatesTable'
import ListTopBar from '../../components/ListTopBar'

import { getProductQuotes } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function CustomRatesList() {
	const [customRates, setCustomRates] = useState(null)
	const [filterType, setFilterType] = useState('rate_code')
	const [filterValue, setFilterValue] = useState('')
    const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(()=>{
		!customRates && getProductQuotes({
            ...pagination,
			include: 'quote.store,quote.user,product',
			[`filter[${filterType}]`]: filterValue,
            'filter[custom]': true
		})
			.then(res => {
                setCustomRates(res.data.data)
                setPagination(parsePagination(res))
            })
			.catch(error => handleError(error))
	}, [customRates, filterType, filterValue, pagination])

    return (
        <React.Fragment>
            <ListTopBar 
                filterType={filterType}
                setFilterType={setFilterType}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                title="Tarifas Personalizadas Cotizador"
                subtitle="Listado de tarifas personalizadas por asesores"
                reload={() => setCustomRates(null)}
                options={[
                    { label: 'Buscar por codigo de tarifa', value: 'rate_code' },
                ]}
            />
            <Card>
                <CardBody>
                    <CustomRatesTable 
                        customRates={customRates} 
                        reload={()=>setCustomRates(null)}
                        pagination={pagination}
						setPagination={setPagination}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )
}