import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DatePicker } from 'antd'
import { Form } from 'react-bootstrap'
import moment from 'moment'

import 'moment/locale/es'
import locale from 'antd/es/date-picker/locale/es_ES'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchUsers } from '../../Users/services'
import { searchUserStores } from '../../Stores/services'

export default function VisitForm({ visit, register, errors, setValue }) {
	const currentUser = useSelector(state => state.auth.user)
	const [date, setDate] = useState( visit ? moment(visit.schedule_at).format('YYYY-MM-DD hh:mm a') : null )
	const [user, setUser] = useState( visit ? { value: visit.user_id, label: visit.user.name} : [])
	const [store, setStore] = useState( visit ? { value: visit.store_id, label: visit.store.name} : [])

	useEffect(() => {
		register('user_id', { required: true })
		register('store_id', { required: true })
		register('schedule_at', { required: true })
	}, [ register ])

	useEffect(() => {
		setValue('user_id', user.value)
		setValue('store_id', store.value)
		setValue('schedule_at', moment(date).format('YYYY-MM-DD HH:mm'))
	}, [ user, store, date, setValue ])

	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Usuario</Form.Label>
				<ServerSideSelect
					value={user}
					placeholder="Seleccione un usuario"
					fetchOptions={searchUsers}
					onChange={value => setUser(value)}
					className="full-width"
				/>
				{ errors.user_id && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Tienda</Form.Label>
				<ServerSideSelect
					value={store}
					placeholder="Seleccione una tienda"
					fetchOptions={searchUserStores}
					onChange={value => setStore(value)}
					className="full-width"
					userId={currentUser.id}
				/>
				{ errors.store_id && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Fecha de Visita <span className="text-primary">*</span></Form.Label>
				<DatePicker 
					value={date}
					onChange={setDate}
					format="YYYY-MM-DD hh:mm a"
					placeholder="Elija una fecha"
					className="full-width"
					locale={locale}
					showTime
				/>
			</Form.Group>
		</React.Fragment>
	)
}