import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { message } from 'antd'

import ListTopBar from '../../components/ListTopBar'
import MetricResponsesTable from './partials/MetricResponsesTable'

import { getUri } from '../../services/springApi'
import { storeExportToken } from '../ExportTokens/services'
import { getMetricResponses } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function MetricResponsesList() {
	const { user } = useSelector(state => state.auth)
	const [metricResponses, setMetricResponses] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [filterType, setFilterType] = useState('user.name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(()=>{
		!metricResponses && getMetricResponses({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'metricList,user,metrics,store'
		})
			.then(res => {
				setMetricResponses(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [metricResponses, pagination, filterType, filterValue])

	const onExport = () => {
		const token = Date.now() + Math.floor(1000 + Math.random() * 9000)

		storeExportToken({ user_id: user.id, type: 'Exportar Metricas', token })
			.then(() => {
				message.info('Descarga en iniciada correctamente')
				window.open(`${getUri()}/exports/metrics/${token}`)
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setMetricResponses(null)}
				title="Listado de Métricas Registradas"
				subtitle="Listados de métricas registradas desde el aplicativo"
				options={[
					{ label: 'Buscar por nombre de usuario', value: 'user.name' },
					{ label: 'Buscar por nombre de listado', value: 'metric_list.name' },
					{ label: 'Buscar por fecha (YYYY-MM-DD)', value: 'created_at' },
					{ label: 'Buscar por nombre de tienda', value: 'store.name' },
				]}
				onExport={onExport}
			/>
			<Card>				
				<CardBody>
					<MetricResponsesTable 
						metricResponses={metricResponses} 
						reload={()=>setMetricResponses(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}