import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faBuilding, faTags, faPercent, faBoxes, faCashRegister, faEdit } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function PricingsDashboard() {
	return (
		<React.Fragment>
			<PageTitle title="Tarifas y Activaciones" subtitle="Listado de tarifas y activaciones de productos Spring y productos de la competencia." />
			<Row>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Dinámicas Spring"
						subtitle="Consultar, crear y actualizar activaciones"
						icon={faPercent}
						linkTo={"/promos"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Competidores"
						subtitle="Consultar, crear y actualizar competidores"
						icon={faBuilding}
						linkTo={"/competitors"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Referencias Competencia"
						subtitle="Administrar las referencias de competidores"
						icon={faBoxes}
						linkTo={"/competitor_references"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Tarifas Competencia"
						subtitle="Tarifas y activaciones de competidores"
						icon={faTags}
						linkTo={"/competitor_rates"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Referencias Spring"
						subtitle="Referencias del Cotizador Web Spring"
						icon={faCashRegister}
						linkTo={"/products"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Tarifas Personalizadas"
						subtitle="Tarifas personalizadas usadas en cotizador"
						icon={faEdit}
						linkTo={"/custom_rates"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}