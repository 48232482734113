import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import TaskListsList from './TaskListsList'

export default function TaskLists() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/task_lists" component={TaskListsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}