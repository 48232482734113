import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'
import { handleError } from '../../helpers'

import NewCompetitorForm from './partials/NewCompetitorForm'
import CompetitorsTable from './partials/CompetitorsTable'
import { getCompetitors } from './services'

export default function CompetitorsList() {
	const [competitors, setCompetitors] = useState(null)

	useEffect(()=>{
		!competitors && getCompetitors()
			.then(res => setCompetitors(res.data.data))
			.catch(error => handleError(error))
	}, [competitors])

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Competidor</CardTitle>
							<CardSubtitle>Ingrese los datos del competidor</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewCompetitorForm reload={()=>setCompetitors(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="7">
					<Card>
						<CardHeader>
							<CardTitle>Listado de Competidores</CardTitle>
							<CardSubtitle>Administre las marcas registradas</CardSubtitle>
						</CardHeader>
						<CardBody>
							<CompetitorsTable competitors={competitors} reload={()=>setCompetitors(null)} />
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</React.Fragment>
	)
}