import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import { Badge } from 'reactstrap'
import { Table, Popconfirm, message, Tooltip } from 'antd'

import Pagination from '../../../components/Pagination'

import { toggleProducts } from '../services'
import { handleError } from '../../../helpers'

export default function ProductsTable({ products, pagination, setPagination, reload }) {

	const handleToogle = (parent_key, disabled) => {
		toggleProducts({ parent_key, disabled })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'name',
			width: '350px'
		},
		{
			title: 'Referencia',
			dataIndex: 'reference'
		},
		{
			title: 'Estado',
			dataIndex: 'disabled_at',
			render: t => !t ? <Badge color='success'>Activo</Badge> : <Badge color="warning">Inactivo</Badge>
		},
		{
			title: 'Categoría',
			dataIndex: 'category'
		},
		{
			title: 'Sub Categoría',
			dataIndex: 'section'
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title={!r.disabled_at ? 'Desactivar' : 'Activar'}>
						<Popconfirm 
							title="El cambio se verá reflejado de inmediato"
							okText={!r.disabled_at ? 'Desactivar' : 'Activar'}
							cancelText="Cancelar"
							onConfirm={() => !r.disabled_at ? handleToogle(r.parent_key, 1) : handleToogle(r.parent_key, 0)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon 
								className="text-link" 
								icon={!r.disabled_at ? faToggleOff : faToggleOn} 
							/>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-1'>
				<b>Códigos LN: </b>{r.codes}
			</p>
			<p className='mb-1'>
				<b>Colores: </b>{r.colors}
			</p>
			<p className='mb-1'>
				<b>Tallas: </b>{r.sizes}
			</p>
		</React.Fragment>
	)

	return (
		<React.Fragment>
			<Table 
				dataSource={products}
				columns={columns}
				rowKey={r => r.id}
				expandable={{ expandedRowRender }}
				size="small"
				pagination={false}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
			/>
		</React.Fragment>
	)
}