import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import MetricsDashboard from './MetricsDashboard'
import PricingsDashboard from './PricingsDashboard'
import ReportsDashboard from './ReportsDashboard'
import SettingsDashboard from './SettingsDashboard'
import SurveysDashboard from './SurveysDashboard'
import TasksDashboard from './TasksDashboard'
import TicketsDashboard from './TicketsDashboard'

import VisitsDashboard from './VisitsDashboard'

export default function Dashboards() {
	return (
		<React.Fragment>
			<Switch>
				<Redirect exact path="/dashboards" to="/dashboards/settings" />

				<Route exact path="/dashboards/visits" component={VisitsDashboard} />
				<Route exact path="/dashboards/tasks" component={TasksDashboard} />
				<Route exact path="/dashboards/surveys" component={SurveysDashboard} />
				<Route exact path="/dashboards/tickets" component={TicketsDashboard} />
				<Route exact path="/dashboards/pricings" component={PricingsDashboard} />
				<Route exact path="/dashboards/metrics" component={MetricsDashboard} />
				<Route exact path="/dashboards/reports" component={ReportsDashboard} />
				<Route exact path="/dashboards/settings" component={SettingsDashboard} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}