import React from 'react'
import { Col, Row } from 'reactstrap'
import { Table } from 'antd'

import Pagination from '../../../components/Pagination'
import { currency, roundZero } from '../../../helpers'

export default function CustomRatesTable({ customRates, reload, pagination, setPagination }) {

	const columns = [
        {
            title: 'Asesor',
            dataIndex: 'quote',
            render: t => t.user.name
        },
        {
            title: 'Tienda',
            dataIndex: 'quote',
            render: t => `[${t.store.code}] ${t.store.name}`
        },
        {
            title: 'Producto',
            dataIndex: 'product',
            render: t => t.name
        },
        {
            title: 'Tarifa',
            dataIndex: 'rate_desc',
            responsive: ['lg'],
        },
        {
            title: 'Precio Und Original',
            dataIndex: 'before_edit_price',
            render: t => `$${currency(roundZero(t))} COP`
        },
        {
            title: 'Precio Und Cotizado',
            dataIndex: 'net_price',
            render: t => `$${currency(roundZero(t))} COP`
        },
        {
            title: 'Ctd',
            dataIndex: 'quantity'
        },
        {
            title: 'Dcto',
            dataIndex: 'discount',
            render: t => `${t}%`
        },
	]

    const expandedRowRender = r => (
        <React.Fragment>
            <Row>
                <Col>
                    <p><strong>Código de Tarifa:</strong> {r.rate_code}</p>
                    <p><strong>Descripción de Tarifa:</strong> {r.rate_desc}</p>
                </Col>
                <Col>
                    <p><strong>Precio Bruto:</strong> ${currency(r.gross_price)} COP</p>
                    <p><strong>Descuento:</strong> {r.discount}%</p>
                </Col>
            </Row>
        </React.Fragment>
    )

	return (
		<React.Fragment>
			<Table 
				columns={columns}
				dataSource={customRates}
				rowKey={record => record.id}
				locale={{ emptyText: 'No se encontraron tarifas' }}
                expandable={{ expandedRowRender }}
				pagination={false}
                size="small"
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
		</React.Fragment>
	)
}