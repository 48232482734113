import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Row } from 'reactstrap'

import NotificationsTable from './partials/NotificationsTable'
import NewNotificationForm from './partials/NewNotificationForm'
import { getNotifications } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function NotificationsList() {
	const [notifications, setNotifications] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!notifications && getNotifications({
			...pagination
		})
			.then(res => {
				setNotifications(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	})

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Notificación</CardTitle>
							<CardSubtitle>Ingrese los datos de la notificación</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewNotificationForm reload={() => setNotifications(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<Card>
						<CardHeader>
							<CardTitle>Listado de Notificaciones</CardTitle>
							<CardSubtitle>Administre las notificaciones de la plataforma</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NotificationsTable
								notifications={notifications}
								reload={() => setNotifications(null)}
								pagination={pagination}
								setPagination={setPagination}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}