import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import CompetitorRatesList from './CompetitorRatesList'

export default function CompetitorRates() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/competitor_rates" component={CompetitorRatesList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}