import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import PromoForm from './PromoForm'
import { updatePromo } from '../services'
import { handleError } from '../../../helpers'

export default function EditPromoModal({ visible, onClose, reload, promo }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		updatePromo(promo.id, values)
			.then(() => {
				message.success('Dinámica actualizada exitosamente.')
				reset()
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Dinámica</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<PromoForm register={register} errors={errors} promo={promo} />
						<Button type="submit" color="primary">Actualizar Dinámica</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}