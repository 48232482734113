import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'
import styled from 'styled-components'

import TaskTemplateForm from './TaskTemplateForm'
import { storeTaskTemplate } from '../services'
import { handleError } from '../../../helpers'

export default function NewTaskTemplateForm({ taskListId, reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()
	
	const onSubmit = values => {
		values.task_list_id = taskListId
		storeTaskTemplate(values)
			.then(()=>{
				message.success('Tarea creada exitosamente')
				reset()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<Container>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<TaskTemplateForm register={register} errors={errors} />
				<Button type="submit" color="primary">Agregar Tarea</Button>
			</Form>
		</Container>
	)
}

const Container = styled.div`
	background-color: #f8f8f8;
	padding: 20px;	
	max-width: 500px;
	margin: 20px auto 0px;
`