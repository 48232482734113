import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Tooltip, Divider, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faLockOpen, faTasks, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons'

import EditSurveyModal from './EditSurveyModal'
import QuestionsModal from './QuestionsModal'
import ModelStoresModal from '../../Stores/partials/ModelStoresModal'
import ModelRankingsModal from '../../ModelRankings/partials/ModelRankingsModal'

import { deleteSurvey } from '../services'
import { handleError } from '../../../helpers'

export default function SurveysTable({ surveys, reload }) {
	const user = useSelector(state => state.auth.user)
	const [editSurvey, setEditSurvey] = useState(null)
	const [editQuestionsList, setEditQuestionsList] = useState(null)
	const [modelStoresModal, setModelStoresModal] = useState(null)
	const [modelRankingsModal, setModelRankingsModal] = useState(null)

	const handleDelete = id => {
		deleteSurvey(id)
			.then(() => {
				message.success('Encuesta eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Encuesta',
			dataIndex: 'name'
		},
		{
			title: 'Preguntas Asociadas',
			dataIndex: 'questions_count',
			render: t => `${t} preguntas`
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: active => active ? 'Activa' : 'Inactiva'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar Nombre">
						<FontAwesomeIcon className="text-link" icon={faEdit} onClick={()=>setEditSurvey(record)} />
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Preguntas Asociadas">
						<FontAwesomeIcon className="text-link" icon={faTasks} onClick={()=>setEditQuestionsList(record)} />
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Tiendas Permitidas">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faLockOpen} 
							onClick={()=>setModelStoresModal({
								id: record.id,
								type: "Survey",
							})}
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Cargos Asociados">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faUsers} 
							onClick={()=>setModelRankingsModal({
								id: record.id,
								type: "Survey",
							})}
						/>
					</Tooltip>
					<Divider type="vertical" />
					{ user.ranking === 'admin' && <Popconfirm 
						title="¿Desea eliminar la encuesta?"
						okText="Eliminar"
						cancelText="Cancelar"
						onConfirm={() => handleDelete(record.id)}
						okButtonProps={{ danger:true }}
					>
						<FontAwesomeIcon className="text-link" icon={faTrash} />
					</Popconfirm> }
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={surveys}
				columns={columns}
				rowKey={record => record.id}
			/>
			<EditSurveyModal 
				visible={editSurvey ? true : false}
				onClose={()=>setEditSurvey(null)}
				survey={editSurvey}
				reload={reload}
			/>
			<QuestionsModal 
				visible={editQuestionsList ? true : false}
				onClose={()=>{
					setEditQuestionsList(null)
					reload()
				}}
				survey={editQuestionsList}
			/>
			<ModelStoresModal 
				visible={modelStoresModal ? true : false}
				onClose={() => setModelStoresModal(null)}
				{...modelStoresModal}
			/>
			<ModelRankingsModal 
				visible={modelRankingsModal ? true : false}
				onClose={() => setModelRankingsModal(null)}
				{...modelRankingsModal}
			/>
		</React.Fragment>
	)
}