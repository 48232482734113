import springApi from '../../services/springApi'

export const getSurveys = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/surveys', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeSurvey = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/surveys', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSurvey = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/surveys/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteSurvey = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/surveys/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)