import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faVideo, faClipboardList } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function MetricsDashboard() {
	return (
		<React.Fragment>
			<PageTitle title="Métricas de Rendimiento Claves" subtitle="Listado de KPIs registrados por usuarios y manejo de formularios de KPIs." />
			<Row>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Formularios de KPIs"
						subtitle="Crear, editar y eliminar formularios de métricas de rendimiento"
						icon={faClipboardList}
						linkTo={"/metric_lists"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Registros de KPIs"
						subtitle="Consultar respuestas de métricas diligenciadas en App"
						icon={faVideo}
						linkTo={"/metric_responses"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}