import React, { useState } from 'react'
import { Table, Tooltip, Divider, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditCompetitorModal from './EditCompetitorModal'
import { deleteCompetitor } from '../services'
import { handleError } from '../../../helpers'

export default function CompetitorsTable({ competitors, reload }) {
	const [editCompetitor, setEditCompetitor] = useState(null)

	const handleDelete = id => {
		deleteCompetitor(id)
			.then(() => {
				message.success('Competidor eliminado exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Competidor',
			dataIndex: 'name'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar Nombre">
						<FontAwesomeIcon className="text-link" icon={faEdit} onClick={()=>setEditCompetitor(record)} />
					</Tooltip>
					<Divider type="vertical" />
					<Popconfirm 
						title="Se eliminara toda la información relacionada"
						okText="Eliminar"
						cancelText="Cancelar"
						onConfirm={() => handleDelete(record.id)}
						okButtonProps={{ danger:true }}
					>
						<FontAwesomeIcon className="text-link" icon={faTrash} />
					</Popconfirm>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={competitors}
				columns={columns}
				rowKey={record => record.id}
			/>
			<EditCompetitorModal 
				visible={editCompetitor ? true : false}
				onClose={()=>setEditCompetitor(null)}
				competitor={editCompetitor}
				reload={reload}
			/>
		</React.Fragment>
	)
}