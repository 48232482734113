import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faClipboardList, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function SurveysDashboard() {
	return (
		<React.Fragment>
			<PageTitle title="Manejo de Encuestas" subtitle="Administrar encuestas y consulta de respuestas recibidas." />
			<Row>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Listados de Encuestas"
						subtitle="Crear, editar y eliminar encuestas a usuarios"
						icon={faClipboardList}
						linkTo={"/surveys"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-3">
					<MenuWidget 
						title="Respuestas de Encuestas"
						subtitle="Consultar encuestas respondidas por usuarios desde App"
						icon={faClipboardCheck}
						linkTo={"/survey_responses"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}