import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Tooltip, Divider, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTasks, faTrash, faLockOpen } from '@fortawesome/free-solid-svg-icons'

import EditMetricListModal from './EditMetricListModal'
import MetricTemplatesModal from './MetricTemplatesModal'
import ModelStoresModal from '../../Stores/partials/ModelStoresModal'
import { deleteMetricList } from '../services'
import { handleError } from '../../../helpers'

export default function MetricListsTable({ metricLists, reload }) {
	const user = useSelector(state => state.auth.user)
	const [editMetricList, setEditMetricList] = useState(null)
	const [editTemplatesList, setEditTemplatesList] = useState(null)
	const [modelStoresModal, setModelStoresModal] = useState(null)

	const handleDelete = id => {
		deleteMetricList(id)
			.then(() => {
				message.success('Listado de Metricas eliminado exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Listado de Metricas',
			dataIndex: 'name'
		},
		{
			title: 'Métricas Asociadas',
			dataIndex: 'metric_templates_count',
			render: t => `${t} métricas`
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: active => active ? 'Activo' : 'Inactivo'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar Nombre">
						<FontAwesomeIcon className="text-link" icon={faEdit} onClick={()=>setEditMetricList(record)} />
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Métricas Asociadas">
						<FontAwesomeIcon className="text-link" icon={faTasks} onClick={()=>setEditTemplatesList(record)} />
					</Tooltip>
					<Divider type="vertical" />					
					<Tooltip title="Tiendas Permitidas">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faLockOpen} 
							onClick={()=>setModelStoresModal({
								id: record.id,
								type: "MetricList",
							})}
						/>
					</Tooltip>
					{ user.ranking === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar el listado de metricas?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(record.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={metricLists}
				columns={columns}
				rowKey={record => record.id}
			/>
			<EditMetricListModal 
				visible={editMetricList ? true : false}
				onClose={()=>setEditMetricList(null)}
				metricList={editMetricList}
				reload={reload}
			/>
			<MetricTemplatesModal 
				visible={editTemplatesList ? true : false}
				onClose={()=>{
					setEditTemplatesList(null)
					reload()
				}}
				metricList={editTemplatesList}
				reload={reload}
			/>
			<ModelStoresModal 
				visible={modelStoresModal ? true : false}
				onClose={() => setModelStoresModal(null)}
				{...modelStoresModal}
			/>
		</React.Fragment>
	)
}