import React from 'react'
import styled from 'styled-components'
import { Switch, TimePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/es_ES'

import moment from 'moment'

export default function ScheduleCard({ schedule, setSchedule, weekday }) {

	const handleHolidayChange = () => {
		setSchedule({
			...schedule,
			[`${weekday.value}_off`]: !schedule[`${weekday.value}_off`]
		})
	}

	const handleTimeChange = (values) => {
		let [newIn, newOut] = values

		setSchedule({
			...schedule,
			[`${weekday.value}_in`]: newIn ? newIn.format('HH:mm') : '',
			[`${weekday.value}_out`]: newOut ? newOut.format('HH:mm') : '',
		})
	}
	
	return (
		<Container>
			<Title className="text-muted">{weekday.label}</Title>
			
			<Switch 
				size="small" 
				checked={schedule[`${weekday.value}_off`]} 
				onChange={handleHolidayChange} 
			/> Descanso

			<TimePicker.RangePicker 
				disabled={schedule[`${weekday.value}_off`]}
				className="mt-2"
				size="small"
				locale={locale}
				showSecond={false}
				minuteStep={15}
				allowClear={false}
				format="hh:mm a"
				value={[ 
					schedule[`${weekday.value}_in`] ? moment(schedule[`${weekday.value}_in`], 'hh:mm a'): '', 
					schedule[`${weekday.value}_out`] ? moment(schedule[`${weekday.value}_out`], 'hh:mm a') : ''
				]}
				onCalendarChange={handleTimeChange}
			/>
		</Container>
	)
}

const Container = styled.div`
	padding: 10px;
	background-color: #f8f8f8;
	margin: 5px;
`

const Title = styled.h6`
	font-size: 17px;
	margin-bottom: 5px;
`

// import React from 'react'
// import styled from 'styled-components'
// import { message, Switch, TimePicker } from 'antd'
// import locale from 'antd/es/date-picker/locale/es_ES'

// import { storeUserSchedule } from '../services'
// import { handleError } from '../../../helpers'
// import moment from 'moment'

// export default function ScheduleCard({ schedule, weekday, reload, userId, loading }) {

// 	const handleHolidayChange = () => {
// 		storeUserSchedule({
// 			user_id: userId,
// 			...schedule,
// 			[`${weekday.value}_off`]: !schedule[`${weekday.value}_off`]
// 		})
// 			.then(() => message.success('Horario actualizado exitosamente'))
// 			.catch(error => {
// 				handleError(error)
// 				reload()
// 			})
// 	}

// 	const handleTimeChange = ( newIn, newOut ) => {
// 		storeUserSchedule({
// 			user_id: userId,
// 			...schedule,
// 			[`${weekday.value}_in`]: newIn ? newIn.format('HH:mm') : '',
// 			[`${weekday.value}_out`]: newOut ? newOut.format('HH:mm') : '',
// 		})
// 			.then(() => {
// 				message.success('Horario actualizado exitosamente')
// 				reload()
// 			})
// 			.catch(error => handleError(error))
// 	}
	
// 	return (
// 		<Container>
// 			<Title className="text-muted">{weekday.label}</Title>
			
// 			<Switch 
// 				size="small" 
// 				checked={schedule[`${weekday.value}_off`]} 
// 				onChange={handleHolidayChange} 
// 			/> Descanso

// 			<TimePicker.RangePicker 
// 				disabled={schedule[`${weekday.value}_off`]}
// 				className="mt-2"
// 				size="small"
// 				locale={locale}
// 				showSecond={false}
// 				minuteStep={15}
// 				format="hh:mm a"
// 				value={[ 
// 					schedule[`${weekday.value}_in`] ? moment(schedule[`${weekday.value}_in`], 'hh:mm a'): '', 
// 					schedule[`${weekday.value}_out`] ? moment(schedule[`${weekday.value}_out`], 'hh:mm a') : ''
// 				]}
// 				onCalendarChange={([newIn, newOut]) => {
// 					console.log('In: ', newIn)
// 					console.log('Out: ', newOut)
// 					handleTimeChange(newIn, newOut)
// 				}}
// 			/>
// 		</Container>
// 	)
// }

// const Container = styled.div`
// 	padding: 10px;
// 	background-color: #f8f8f8;
// 	margin: 5px;
// `

// const Title = styled.h6`
// 	font-size: 17px;
// 	margin-bottom: 5px;
// `