import springApi from '../../services/springApi'

export const getProducts = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/products', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const toggleProducts = (data) => (
	new Promise((resolve, reject) => {
		springApi()
			.post(`/products/toggle`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)