import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Tooltip, Divider, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditMetricTemplateModal from './EditMetricTemplateModal'
import { deleteMetricTemplate } from '../services'
import { handleError } from '../../../helpers'

export default function MetricTemplatesTable({ metricTemplates, reload }) {
	const user = useSelector(state => state.auth.user)
	const [editMetricTemplate, setEditMetricTemplate] = useState(null)

	const handleDelete = id => {
		deleteMetricTemplate(id)
			.then(() => {
				message.success('Métrica eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Métrica',
			dataIndex: 'name'
		},
		{
			title: 'Tipo',
			dataIndex: 'numeric',
			render: t => t ? 'Numérico' : 'Alfa Numérico'
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: t => t ? 'Activa' : 'Inactiva'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar Tarea">
						<FontAwesomeIcon className="text-link" icon={faEdit} onClick={()=>setEditMetricTemplate(record)} />
					</Tooltip>
					<Divider type="vertical" />
					<Popconfirm 
						title="¿Desea eliminar métrica?"
						okText="Eliminar"
						cancelText="Cancelar"
						onConfirm={() => handleDelete(record.id)}
						okButtonProps={{ danger:true }}
						disabled={user.ranking !== 'admin'}
					>
						<FontAwesomeIcon className="text-link" icon={faTrash} />
					</Popconfirm>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={metricTemplates}
				columns={columns}
				rowKey={record => record.id}
				size="small"
				expandable={{
					expandedRowRender: record => <p className="mb-0">
						<strong>Descripción:</strong> {record.description ? record.description : 'Sin descripión'}
					</p>,
				 }}
			/>
			<EditMetricTemplateModal 
				visible={editMetricTemplate ? true : false}
				onClose={()=>setEditMetricTemplate(null)}
				metricTemplate={editMetricTemplate}
				reload={reload}
			/>
		</React.Fragment>
	)
}