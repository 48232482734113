import { Empty } from 'antd'
import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import CommentCard from './CommentCard'
import NewCommentForm from './NewCommentForm'

export default function CommentsModal({ 
	visible, 
	onClose, 
	comments, 
	reload,
	modelType,
	modelId
}) {
	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Comentarios</ModalHeader>
				<ModalBody>
					<div className="mb-2">
						{ comments && comments.length > 0 ? (
							<React.Fragment>
								{ comments.map( comment => (
									<CommentCard 
										key={comment.id}
										comment={comment} 
										reload={reload} 
									/>
								)) }
							</React.Fragment>
						) : (
							<Empty description="No tiene comentarios" />
						)}
					</div>
					<NewCommentForm 
						modelType={modelType}
						modelId={modelId}
						reload={reload}
						onClose={onClose}
					/>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}