import React, { useState } from 'react'
import { Table, Tooltip, Divider, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditCompetitorReferenceModal from './EditCompetitorReferenceModal'
import { deleteCompetitorReference } from '../services'
import { handleError } from '../../../helpers'

export default function CompetitorReferencesTable({ competitorReferences, reload }) {
	const [editCompetitorReference, setEditCompetitorReference] = useState(null)

	const handleDelete = id => {
		deleteCompetitorReference(id)
			.then(() => {
				message.success('Referencia eliminado exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Competidor',
			dataIndex: 'competitor',
			render: t => t.name
		},
		{
			title: 'Referencia',
			dataIndex: 'name'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit} 
							onClick={()=>setEditCompetitorReference(record)} 
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Popconfirm 
						title="Se eliminara toda la información relacionada"
						okText="Eliminar"
						cancelText="Cancelar"
						onConfirm={() => handleDelete(record.id)}
						okButtonProps={{ danger:true }}
					>
						<FontAwesomeIcon className="text-link" icon={faTrash} />
					</Popconfirm>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={competitorReferences}
				columns={columns}
				rowKey={record => record.id}
			/>
			<EditCompetitorReferenceModal 
				visible={editCompetitorReference ? true : false}
				onClose={()=>setEditCompetitorReference(null)}
				competitorReference={editCompetitorReference}
				reload={reload}
			/>
		</React.Fragment>
	)
}