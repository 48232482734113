import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'
import { handleError } from '../../helpers'

import NewSurveyForm from './partials/NewSurveyForm'
import SurveysTable from './partials/SurveysTable'
import { getSurveys } from './services'

export default function SurveysList() {
	const [surveys, setSurveys] = useState(null)

	useEffect(()=>{
		!surveys && getSurveys({
			include: 'questions_count'
		})
			.then(res => setSurveys(res.data.data))
			.catch(error => handleError(error))
	}, [surveys])

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Encuesta</CardTitle>
							<CardSubtitle>Ingrese los datos de la encuesta</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewSurveyForm reload={()=>setSurveys(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<Card>
						<CardHeader>
							<CardTitle>Listados de Encuestas</CardTitle>
							<CardSubtitle>Administre las encuestas existentes</CardSubtitle>
						</CardHeader>
						<CardBody>
							<SurveysTable surveys={surveys} reload={()=>setSurveys(null)} />
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</React.Fragment>
	)
}