import springApi from '../../services/springApi'

export const getNotifications = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/notifications', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeNotification = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/notifications', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateNotification = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/notifications/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteNotification = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/notifications/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)