import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import Pagination from '../../../components/Pagination'
import EditVisitModal from './EditVisitModal'

import { deleteVisit } from '../services'
import { handleError } from '../../../helpers'
import { Badge } from 'reactstrap'

export default function VisitsTable({ visits, reload, pagination, setPagination }) {
	const user = useSelector(state => state.auth.user)
	const [editVisit, setEditVisit] = useState(null)

	const handleDelete = id => {
		deleteVisit(id)
			.then(() => {
				message.success('Tarifa eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Asesor',
			dataIndex: 'user',
			render: t => t.name
		},
		{
			title: 'Tienda',
			dataIndex: 'store',
			render: t => t.name
		},
		{
			title: 'Programada el',
			dataIndex: 'schedule_at',
			render: t => moment(t).format('YYYY-MMM-DD hh:mm a')
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: status => (
				<Badge color={status.color}>{status.name}</Badge>
			)
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							icon={faEdit} 
							className="text-link"
							onClick={() => setEditVisit(record)}
						/>
					</Tooltip>
					<Divider type="vertical" />
					{ user.ranking === 'admin' && <Popconfirm
						title="¿Segura desea proceder?"
						okText="Eliminar"
						cancelText="Cancelar"
						okButtonProps={{ danger:true }}
						onConfirm={() => handleDelete(record.id)}
					>
						<FontAwesomeIcon icon={faTrash} className="text-link" />
					</Popconfirm> }
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				columns={columns}
				dataSource={visits}
				rowKey={record => record.id}
				locale={{ emptyText: <Empty description='No se encontraron visitas' /> }}
				pagination={false}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			<EditVisitModal 
				visible={editVisit ? true : false}
				onClose={() => setEditVisit(null)}
				reload={reload}
				visit={editVisit}
			/>
		</React.Fragment>
	)
}