import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import { updateTaskTemplate } from '../services'
import { handleError } from '../../../helpers'
import TaskTemplateForm from './TaskTemplateForm'

export default function EditTaskTemplateModal({ visible, onClose, taskTemplate, reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		updateTaskTemplate(taskTemplate.id, values)
			.then(() => {
				message.success('Templateado de Tareas actualizado exitosamente.')
				reload()
				reset()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Templateado de Tareas</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<TaskTemplateForm taskTemplate={taskTemplate} register={register} errors={errors} />
						<Button type="submit" color="primary">Actualizar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}