import React, { useState } from 'react'
import { Table, Tooltip, Divider, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditTicketCategoryModal from './EditTicketCategoryModal'
import { deleteTicketCategory } from '../services'
import { handleError } from '../../../helpers'

export default function TicketCategoriesTable({ ticketCategories, reload }) {
	const [editTicketCategory, setEditTicketCategory] = useState(null)

	const handleDelete = id => {
		deleteTicketCategory(id)
			.then(() => {
				message.success('Categoría eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Categoría de Incidencia',
			dataIndex: 'name'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar Nombre">
						<FontAwesomeIcon className="text-link" icon={faEdit} onClick={()=>setEditTicketCategory(record)} />
					</Tooltip>
					{ record.tickets_count === 0 && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar categoría de incidencia?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(record.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={ticketCategories}
				columns={columns}
				rowKey={record => record.id}
			/>
			<EditTicketCategoryModal 
				visible={editTicketCategory ? true : false}
				onClose={()=>setEditTicketCategory(null)}
				ticketCategory={editTicketCategory}
				reload={reload}
			/>
		</React.Fragment>
	)
}