import React, { useState, useEffect } from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import ScheduleCard from './ScheduleCard'
import { getUserSchedules, storeUserSchedule } from '../services'
import { handleError } from '../../../helpers'
import { message } from 'antd'

export default function UserScheduleModal({ 
	visible, 
	onClose,
	userId
}) {
	const [schedules, setSchedules] = useState(null)
	const [schedule, setSchedule] = useState({})

	useEffect(() => {
		!schedules && visible && getUserSchedules({
			'filter[user_id]': userId
		})
			.then(res => {
				setSchedules(res.data.data)
				setSchedule(res.data.data[0])
			})
			.catch(error => handleError(error))
	}, [ schedules, userId, visible ])

	const weekdays = [
		{ value: 'monday', label: 'Lunes' },
		{ value: 'tuesday', label: 'Martes' },
		{ value: 'wednesday', label: 'Miércoles' },
		{ value: 'thursday', label: 'Jueves' },
		{ value: 'friday', label: 'Viernes' },
		{ value: 'saturday', label: 'Sábado' },
		{ value: 'sunday', label: 'Domingo' },
	]

	const handleClose = () => {
		onClose()
		setSchedules(null)
	}

	const handleSubmit = () => {
		storeUserSchedule({
			user_id: userId,
			...schedule
		})
			.then(() => {
				message.success('Horario actualizado exitosamente')
				setSchedules(null)
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={handleClose} size="lg">
				<ModalHeader toggle={handleClose}>Horarios Asociados</ModalHeader>
				<ModalBody>
					<div className="mb-2">
						<Row noGutters>
							{ weekdays.map( (weekday, i) => (
								<Col key={i} sm="6" md="4">
									<ScheduleCard 
										weekday={weekday}
										schedule={schedule ? schedule : {}}
										setSchedule={setSchedule}
									/>
								</Col>
							)) }
						</Row>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={handleSubmit}>Guardar Cambios</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	)
}