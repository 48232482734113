import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import CustomRatesList from './CustomRatesList'

export default function CustomRates() {
    return (
        <React.Fragment>
            <Switch>
				<Route exact path="/custom_rates" component={CustomRatesList} />

				<Redirect to="/404" />
			</Switch>
        </React.Fragment>
    )
}