import React, { useState, useEffect } from 'react'

import NewPromoDetailForm from './partials/NewPromoDetailForm'
import PromoDetailsTable from './partials/PromoDetailsTable'

import { getPromoDetails } from './services'
import { handleError } from '../../helpers'

export default function PromoDetailsList({ promoId }) {
	const [promoDetails, setPromoDetails] = useState(null)

	useEffect(()=>{
		!promoDetails && getPromoDetails({
			'filter[promo_id]': promoId
		})
			.then(res => setPromoDetails(res.data.data))
			.catch(error => handleError(error))
	}, [promoDetails, promoId])

	return (
		<React.Fragment>
			<PromoDetailsTable 
				promoDetails={promoDetails} 
				reload={()=>setPromoDetails(null)}
			/>
			<NewPromoDetailForm 
				promoId={promoId} 
				reload={()=>setPromoDetails(null)}
			/>
		</React.Fragment>
	)
}