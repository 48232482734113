import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'
import styled from 'styled-components'

import PromoDetailForm from './PromoDetailForm'
import { storePromoDetail } from '../services'
import { handleError } from '../../../helpers'

export default function NewPromoDetailForm({ promoId, reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()
	
	const onSubmit = values => {
		values.promo_id = promoId
		storePromoDetail(values)
			.then(()=>{
				message.success('Detalle de dinámica creada exitosamente')
				reset()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<Container>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<PromoDetailForm register={register} errors={errors} />
				<Button type="submit" color="primary">Agregar Registro</Button>
			</Form>
		</Container>
	)
}

const Container = styled.div`
	background-color: #f8f8f8;
	padding: 20px;	
	max-width: 500px;
	margin: 20px auto 0px;
`