import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import SurveyForm from './SurveyForm'
import { storeSurvey } from '../services'
import { handleError } from '../../../helpers'

export default function NewSurveyForm({ reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm();

	const onSubmit = values => {
		storeSurvey(values)
			.then(() => {
				message.success('Encuesta creada exitosamente.')
				reload()
				reset()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<SurveyForm register={register} errors={errors} />
				<Button type="submit" color="primary">Crear Encuesta</Button>
			</Form>
		</React.Fragment>
	)
}