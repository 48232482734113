import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody} from 'reactstrap'

import CheckinsTable from './partials/CheckinsTable'
import ListTopBar from '../../components/ListTopBar'
import ServerSideSelect from '../../components/ServerSideSelect'

import { getCheckins } from './services'
import { searchUserStores } from '../Stores/services'
import { handleError, parsePagination } from '../../helpers'

export default function CheckinsList() {
	const user = useSelector(state => state.auth.user)
	const [checkins, setCheckins] = useState(null)
	const [filterType, setFilterType] = useState('user.name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [filterStore, setFilterStore] = useState({ label: user.current_store_name, value: user.current_store })

	useEffect(() => {
		!checkins && getCheckins({
			...pagination,
			'filter[store_id]': filterStore.value,
			[`filter[${filterType}]`]: filterValue,
			include: 'visit,user,store',
			sort: '-created_at',
		})
			.then(res => {
				setCheckins(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ checkins, pagination, filterStore, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setCheckins(null)}
				title="Registros de Check In"
				subtitle="Listados de check in registrados desde app"
				options={[
					{ label: 'Buscar por nombre del asesor', value: 'user.name' },
					{ label: 'Buscar por fecha de registro', value: 'created_at' },
					{ label: 'Buscar por categoría', value: 'checkin_type' },
					{ label: 'Buscar por código de tienda', value: 'store.code' },
					{ label: 'Buscar por nombre de tienda', value: 'store.name' },
				]}
			/>
			<Card>
				<CardBody>
					<ServerSideSelect
						value={filterStore}
						fetchOptions={searchUserStores}
						onChange={value => {
							setFilterStore(value)
							setCheckins(null)
						}}
						userId={user.id}
						className="search-top-bar"
					/>
					<CheckinsTable
						checkins={checkins}
						reload={()=>setCheckins(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}