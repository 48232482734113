import React from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import VisitForm from './VisitForm'
import { storeVisit } from '../services'
import { handleError } from '../../../helpers'

export default function NewVisitModal({ visible, onClose, reload }) {
	const auth = useSelector(state => state.auth)
	const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm()

	const onSubmit = values => {
		values.user_id = auth.user.id
		storeVisit(values)
			.then(() => {
				message.success('Visita creada exitosamente.')
				reset()
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Registrar Visita</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<VisitForm register={register} errors={errors} setValue={setValue} />
						<Button type="submit" color="primary">Crear Visita</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}