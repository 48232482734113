import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

export default function StoreForm({ register, errors, store }) {
	return (
		<React.Fragment>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Nombre de la Tienda <span className="text-danger">*</span></Form.Label>
					<Form.Control {...register("name", {required: true})} defaultValue={store && store.name} />
					{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col} md="4">
					<Form.Label>Código <span className="text-danger">*</span></Form.Label>
					<Form.Control 
						{...register("code", {required: store ? false : true})} 
						defaultValue={store && store.code} 
						disabled={store ? true : false} 
					/>
					{ errors.code && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Regional <small>(opcional)</small></Form.Label>
					<Form.Control 
						{...register("region")}
						as="select"
						defaultValue={store && store.region}
					>
						<option value="">SIN ESPECIFICAR</option>
						<option>BOGOTA NORTE</option>
						<option>BOGOTA SUR</option>
						<option>OCCIDENTE</option>
						<option>COSTA</option>
						<option>ANTIOQUIA</option>
						<option>EJE CAFETERO</option>
						<option>SANTANDER</option>
						<option>ZONA CENTRO</option>
						<option>PERIFERIA BOYACÁ</option>
						<option>LLANOS</option>
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Categoría <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register("type")}
						as="select"
						defaultValue={store && store.type}
					>
						<option value="">:: Selecciona una opcion ::</option>
						<option>SPRING</option>
						<option>OUTLET</option>
						<option>SP ESENTIAL</option>
						<option>FRANQUICIA</option>
						<option>TEMPUR</option>
						<option>ROSEN</option>
						<option>SIN ESPECIFICAR</option>
					</Form.Control>
					{ errors.type && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Dirección <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register("address")} 
					defaultValue={store && store.address}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Ciudad <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register("city")}
					as="select"
					defaultValue={store && store.city}
				>
					<option value="">SIN ESPECIFICAR</option>
					{ cities.map( (city, i) => <option key={i}>{city}</option> ) }
				</Form.Control>
			</Form.Group>
			<Form.Group>
				<Form.Label>Correo Electrónico <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register("email")} 
					defaultValue={store && store.email}
				/>
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Celular <small>(opcional)</small></Form.Label>
					<Form.Control 
						{...register("mobile")} 
						defaultValue={store && store.mobile}
					/>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Teléfono Fijo <small>(opcional)</small></Form.Label>
					<Form.Control 
						{...register("phone")} 
						defaultValue={store && store.phone}
					/>
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Horario de atención <small>(opcional)</small></Form.Label>
				<Form.Control 
					as="textarea"
					{...register("schedule")} 
					defaultValue={store && store.schedule}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Frecuencia de Visita</Form.Label>
				<Form.Control 
					{...register("visit_frecuence")}
					as="select"
					defaultValue={store && store.visit_frecuence}
				>
					<option value="">SIN ESPECIFICAR</option>
					<option>SEMANAL</option>
					<option>QUINCENAL</option>
					<option>MENSUAL</option>
					<option>BIMESTRAL</option>
					<option>TRIMESTRAL</option>
					<option>SEMESTRAL</option>
				</Form.Control>
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Marca <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register("brand", { required: true })}
						as="select"
						defaultValue={store && store.brand}
					>
						<option value="">:: Selecciona una opcion ::</option>
						<option>SPRING</option>
						<option>TEMPUR</option>
						<option>FALABELLA</option>
						<option>ROSEN</option>
						<option>SIN ESPECIFICAR</option>
					</Form.Control>
					{ errors.brand && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Canal <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register("channel", { required: true })}
						as="select"
						defaultValue={store && store.channel}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option>RETAIL</option>
						<option>INDIRECTO</option>
						<option>SIN ESPECIFICAR</option>
					</Form.Control>
					{ errors.channel && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Latitud <small>(opcional)</small></Form.Label>
					<Form.Control 
						{...register("latitude")} 
						defaultValue={store && store.latitude}
					/>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Longitud <small>(opcional)</small></Form.Label>
					<Form.Control 
						{...register("longitude")} 
						defaultValue={store && store.longitude}
					/>
				</Form.Group>
			</Row>
			<Form.Label>Logos en Cotización <span className='text-danger'>*</span></Form.Label>
			<Form.Group>
				<Form.Control 
					{...register("logos", { required: true })}
					as="select"
					defaultValue={store && store.logos}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="spring">Solo Logo Spring</option>
					<option value="tempur">Solo Logo Tempur</option>
					<option value="rosen">Solo Logo Rosen</option>
					<option value="tempur,rosen">Logo Tempur y Rosen</option>
					<option value="spring,tempur,rosen">Logo Spring, Tempur y Rosen</option>
					<option value="spring,tempur">Logo Spring y Tempur</option>
				</Form.Control>
				{ errors.logos && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}

const cities = [
	'BOGOTA, D.C. (BOGOTA)',
	'MEDELLIN (ANTIOQUIA)',
	'CALI (VALLE DEL CAUCA)',
	'CARTAGENA (BOLIVAR)',
	'BARRANQUILLA (ATLANTICO)',
	'SANTA MARTA (MAGDALENA)',
	'PEREIRA (RISARALDA)',
	'CUCUTA (N. DE SANTANDER)',
	'ABEJORRAL (ANTIOQUIA)',
	'ABRIAQUI (ANTIOQUIA)',
	'ALEJANDRIA (ANTIOQUIA)',
	'AMAGA (ANTIOQUIA)',
	'AMALFI (ANTIOQUIA)',
	'ANDES (ANTIOQUIA)',
	'ANGELOPOLIS (ANTIOQUIA)',
	'ANGOSTURA (ANTIOQUIA)',
	'ANORI (ANTIOQUIA)',
	'SANTAFE DE ANTIOQUIA (ANTIOQUIA)',
	'ANZA (ANTIOQUIA)',
	'APARTADO (ANTIOQUIA)',
	'ARBOLETES (ANTIOQUIA)',
	'ARGELIA (ANTIOQUIA)',
	'ARMENIA (ANTIOQUIA)',
	'BARBOSA (ANTIOQUIA)',
	'BELMIRA (ANTIOQUIA)',
	'BELLO (ANTIOQUIA)',
	'BETANIA (ANTIOQUIA)',
	'BETULIA (ANTIOQUIA)',
	'CIUDAD BOLIVAR (ANTIOQUIA)',
	'BRICEÑO (ANTIOQUIA)',
	'BURITICA (ANTIOQUIA)',
	'CACERES (ANTIOQUIA)',
	'CAICEDO (ANTIOQUIA)',
	'CALDAS (ANTIOQUIA)',
	'CAMPAMENTO (ANTIOQUIA)',
	'CAÑASGORDAS (ANTIOQUIA)',
	'CARACOLI (ANTIOQUIA)',
	'CARAMANTA (ANTIOQUIA)',
	'CAREPA (ANTIOQUIA)',
	'EL CARMEN DE VIBORAL (ANTIOQUIA)',
	'CAROLINA (ANTIOQUIA)',
	'CAUCASIA (ANTIOQUIA)',
	'CHIGORODO (ANTIOQUIA)',
	'CISNEROS (ANTIOQUIA)',
	'COCORNA (ANTIOQUIA)',
	'CONCEPCION (ANTIOQUIA)',
	'CONCORDIA (ANTIOQUIA)',
	'COPACABANA (ANTIOQUIA)',
	'DABEIBA (ANTIOQUIA)',
	'DON MATIAS (ANTIOQUIA)',
	'EBEJICO (ANTIOQUIA)',
	'EL BAGRE (ANTIOQUIA)',
	'ENTRERRIOS (ANTIOQUIA)',
	'ENVIGADO (ANTIOQUIA)',
	'FREDONIA (ANTIOQUIA)',
	'FRONTINO (ANTIOQUIA)',
	'GIRALDO (ANTIOQUIA)',
	'GIRARDOTA (ANTIOQUIA)',
	'GOMEZ PLATA (ANTIOQUIA)',
	'GRANADA (ANTIOQUIA)',
	'GUADALUPE (ANTIOQUIA)',
	'GUARNE (ANTIOQUIA)',
	'GUATAPE (ANTIOQUIA)',
	'HELICONIA (ANTIOQUIA)',
	'HISPANIA (ANTIOQUIA)',
	'ITAGUI (ANTIOQUIA)',
	'ITUANGO (ANTIOQUIA)',
	'JARDIN (ANTIOQUIA)',
	'JERICO (ANTIOQUIA)',
	'LA CEJA (ANTIOQUIA)',
	'LA ESTRELLA (ANTIOQUIA)',
	'LA PINTADA (ANTIOQUIA)',
	'LA UNION (ANTIOQUIA)',
	'LIBORINA (ANTIOQUIA)',
	'MACEO (ANTIOQUIA)',
	'MARINILLA (ANTIOQUIA)',
	'MONTEBELLO (ANTIOQUIA)',
	'MURINDO (ANTIOQUIA)',
	'MUTATA (ANTIOQUIA)',
	'NARIÑO (ANTIOQUIA)',
	'NECOCLI (ANTIOQUIA)',
	'NECHI (ANTIOQUIA)',
	'OLAYA (ANTIOQUIA)',
	'PEÐOL (ANTIOQUIA)',
	'PEQUE (ANTIOQUIA)',
	'PUEBLORRICO (ANTIOQUIA)',
	'PUERTO BERRIO (ANTIOQUIA)',
	'PUERTO NARE (ANTIOQUIA)',
	'PUERTO TRIUNFO (ANTIOQUIA)',
	'REMEDIOS (ANTIOQUIA)',
	'RETIRO (ANTIOQUIA)',
	'RIONEGRO (ANTIOQUIA)',
	'SABANALARGA (ANTIOQUIA)',
	'SABANETA (ANTIOQUIA)',
	'SALGAR (ANTIOQUIA)',
	'SAN ANDRES DE CUERQUIA (ANTIOQUIA)',
	'SAN CARLOS (ANTIOQUIA)',
	'SAN FRANCISCO (ANTIOQUIA)',
	'SAN JERONIMO (ANTIOQUIA)',
	'SAN JOSE DE LA MONTAÑA (ANTIOQUIA)',
	'SAN JUAN DE URABA (ANTIOQUIA)',
	'SAN LUIS (ANTIOQUIA)',
	'SAN PEDRO (ANTIOQUIA)',
	'SAN PEDRO DE URABA (ANTIOQUIA)',
	'SAN RAFAEL (ANTIOQUIA)',
	'SAN ROQUE (ANTIOQUIA)',
	'SAN VICENTE (ANTIOQUIA)',
	'SANTA BARBARA (ANTIOQUIA)',
	'SANTA ROSA DE OSOS (ANTIOQUIA)',
	'SANTO DOMINGO (ANTIOQUIA)',
	'EL SANTUARIO (ANTIOQUIA)',
	'SEGOVIA (ANTIOQUIA)',
	'SONSON (ANTIOQUIA)',
	'SOPETRAN (ANTIOQUIA)',
	'TAMESIS (ANTIOQUIA)',
	'TARAZA (ANTIOQUIA)',
	'TARSO (ANTIOQUIA)',
	'TITIRIBI (ANTIOQUIA)',
	'TOLEDO (ANTIOQUIA)',
	'TURBO (ANTIOQUIA)',
	'URAMITA (ANTIOQUIA)',
	'URRAO (ANTIOQUIA)',
	'VALDIVIA (ANTIOQUIA)',
	'VALPARAISO (ANTIOQUIA)',
	'VEGACHI (ANTIOQUIA)',
	'VENECIA (ANTIOQUIA)',
	'VIGIA DEL FUERTE (ANTIOQUIA)',
	'YALI (ANTIOQUIA)',
	'YARUMAL (ANTIOQUIA)',
	'YOLOMBO (ANTIOQUIA)',
	'YONDO (ANTIOQUIA)',
	'ZARAGOZA (ANTIOQUIA)',	
	'BARANOA (ATLANTICO)',
	'CAMPO DE LA CRUZ (ATLANTICO)',
	'CANDELARIA (ATLANTICO)',
	'GALAPA (ATLANTICO)',
	'JUAN DE ACOSTA (ATLANTICO)',
	'LURUACO (ATLANTICO)',
	'MALAMBO (ATLANTICO)',
	'MANATI (ATLANTICO)',
	'PALMAR DE VARELA (ATLANTICO)',
	'PIOJO (ATLANTICO)',
	'POLONUEVO (ATLANTICO)',
	'PONEDERA (ATLANTICO)',
	'PUERTO COLOMBIA (ATLANTICO)',
	'REPELON (ATLANTICO)',
	'SABANAGRANDE (ATLANTICO)',
	'SABANALARGA (ATLANTICO)',
	'SANTA LUCIA (ATLANTICO)',
	'SANTO TOMAS (ATLANTICO)',
	'SOLEDAD (ATLANTICO)',
	'SUAN (ATLANTICO)',
	'TUBARA (ATLANTICO)',
	'USIACURI (ATLANTICO)',		
	'ACHI (BOLIVAR)',
	'ALTOS DEL ROSARIO (BOLIVAR)',
	'ARENAL (BOLIVAR)',
	'ARJONA (BOLIVAR)',
	'ARROYOHONDO (BOLIVAR)',
	'BARRANCO DE LOBA (BOLIVAR)',
	'CALAMAR (BOLIVAR)',
	'CANTAGALLO (BOLIVAR)',
	'CICUCO (BOLIVAR)',
	'CORDOBA (BOLIVAR)',
	'CLEMENCIA (BOLIVAR)',
	'EL CARMEN DE BOLIVAR (BOLIVAR)',
	'EL GUAMO (BOLIVAR)',
	'EL PEÑON (BOLIVAR)',
	'HATILLO DE LOBA (BOLIVAR)',
	'MAGANGUE (BOLIVAR)',
	'MAHATES (BOLIVAR)',
	'MARGARITA (BOLIVAR)',
	'MARIA LA BAJA (BOLIVAR)',
	'MONTECRISTO (BOLIVAR)',
	'MOMPOS (BOLIVAR)',
	'NOROSI (BOLIVAR)',
	'MORALES (BOLIVAR)',
	'PINILLOS (BOLIVAR)',
	'REGIDOR (BOLIVAR)',
	'RIO VIEJO (BOLIVAR)',
	'SAN CRISTOBAL (BOLIVAR)',
	'SAN ESTANISLAO (BOLIVAR)',
	'SAN FERNANDO (BOLIVAR)',
	'SAN JACINTO (BOLIVAR)',
	'SAN JACINTO DEL CAUCA (BOLIVAR)',
	'SAN JUAN NEPOMUCENO (BOLIVAR)',
	'SAN MARTIN DE LOBA (BOLIVAR)',
	'SAN PABLO (BOLIVAR)',
	'SANTA CATALINA (BOLIVAR)',
	'SANTA ROSA (BOLIVAR)',
	'SANTA ROSA DEL SUR (BOLIVAR)',
	'SIMITI (BOLIVAR)',
	'SOPLAVIENTO (BOLIVAR)',
	'TALAIGUA NUEVO (BOLIVAR)',
	'TIQUISIO (BOLIVAR)',
	'TURBACO (BOLIVAR)',
	'TURBANA (BOLIVAR)',
	'VILLANUEVA (BOLIVAR)',
	'ZAMBRANO (BOLIVAR)',
	'TUNJA (BOYACA)',
	'ALMEIDA (BOYACA)',
	'AQUITANIA (BOYACA)',
	'ARCABUCO (BOYACA)',
	'BELEN (BOYACA)',
	'BERBEO (BOYACA)',
	'BETEITIVA (BOYACA)',
	'BOAVITA (BOYACA)',
	'BOYACA (BOYACA)',
	'BRICEÑO (BOYACA)',
	'BUENAVISTA (BOYACA)',
	'BUSBANZA (BOYACA)',
	'CALDAS (BOYACA)',
	'CAMPOHERMOSO (BOYACA)',
	'CERINZA (BOYACA)',
	'CHINAVITA (BOYACA)',
	'CHIQUINQUIRA (BOYACA)',
	'CHISCAS (BOYACA)',
	'CHITA (BOYACA)',
	'CHITARAQUE (BOYACA)',
	'CHIVATA (BOYACA)',
	'CIENEGA (BOYACA)',
	'COMBITA (BOYACA)',
	'COPER (BOYACA)',
	'CORRALES (BOYACA)',
	'COVARACHIA (BOYACA)',
	'CUBARA (BOYACA)',
	'CUCAITA (BOYACA)',
	'CUITIVA (BOYACA)',
	'CHIQUIZA (BOYACA)',
	'CHIVOR (BOYACA)',
	'DUITAMA (BOYACA)',
	'EL COCUY (BOYACA)',
	'EL ESPINO (BOYACA)',
	'FIRAVITOBA (BOYACA)',
	'FLORESTA (BOYACA)',
	'GACHANTIVA (BOYACA)',
	'GAMEZA (BOYACA)',
	'GARAGOA (BOYACA)',
	'GUACAMAYAS (BOYACA)',
	'GUATEQUE (BOYACA)',
	'GUAYATA (BOYACA)',
	'GsICAN (BOYACA)',
	'IZA (BOYACA)',
	'JENESANO (BOYACA)',
	'JERICO (BOYACA)',
	'LABRANZAGRANDE (BOYACA)',
	'LA CAPILLA (BOYACA)',
	'LA VICTORIA (BOYACA)',
	'LA UVITA (BOYACA)',
	'VILLA DE LEYVA (BOYACA)',
	'MACANAL (BOYACA)',
	'MARIPI (BOYACA)',
	'MIRAFLORES (BOYACA)',
	'MONGUA (BOYACA)',
	'MONGUI (BOYACA)',
	'MONIQUIRA (BOYACA)',
	'MOTAVITA (BOYACA)',
	'MUZO (BOYACA)',
	'NOBSA (BOYACA)',
	'NUEVO COLON (BOYACA)',
	'OICATA (BOYACA)',
	'OTANCHE (BOYACA)',
	'PACHAVITA (BOYACA)',
	'PAEZ (BOYACA)',
	'PAIPA (BOYACA)',
	'PAJARITO (BOYACA)',
	'PANQUEBA (BOYACA)',
	'PAUNA (BOYACA)',
	'PAYA (BOYACA)',
	'PAZ DE RIO (BOYACA)',
	'PESCA (BOYACA)',
	'PISBA (BOYACA)',
	'PUERTO BOYACA (BOYACA)',
	'QUIPAMA (BOYACA)',
	'RAMIRIQUI (BOYACA)',
	'RAQUIRA (BOYACA)',
	'RONDON (BOYACA)',
	'SABOYA (BOYACA)',
	'SACHICA (BOYACA)',
	'SAMACA (BOYACA)',
	'SAN EDUARDO (BOYACA)',
	'SAN JOSE DE PARE (BOYACA)',
	'SAN LUIS DE GACENO (BOYACA)',
	'SAN MATEO (BOYACA)',
	'SAN MIGUEL DE SEMA (BOYACA)',
	'SAN PABLO DE BORBUR (BOYACA)',
	'SANTANA (BOYACA)',
	'SANTA MARIA (BOYACA)',
	'SANTA ROSA DE VITERBO (BOYACA)',
	'SANTA SOFIA (BOYACA)',
	'SATIVANORTE (BOYACA)',
	'SATIVASUR (BOYACA)',
	'SIACHOQUE (BOYACA)',
	'SOATA (BOYACA)',
	'SOCOTA (BOYACA)',
	'SOCHA (BOYACA)',
	'SOGAMOSO (BOYACA)',
	'SOMONDOCO (BOYACA)',
	'SORA (BOYACA)',
	'SOTAQUIRA (BOYACA)',
	'SORACA (BOYACA)',
	'SUSACON (BOYACA)',
	'SUTAMARCHAN (BOYACA)',
	'SUTATENZA (BOYACA)',
	'TASCO (BOYACA)',
	'TENZA (BOYACA)',
	'TIBANA (BOYACA)',
	'TIBASOSA (BOYACA)',
	'TINJACA (BOYACA)',
	'TIPACOQUE (BOYACA)',
	'TOCA (BOYACA)',
	'TOGsI (BOYACA)',
	'TOPAGA (BOYACA)',
	'TOTA (BOYACA)',
	'TUNUNGUA (BOYACA)',
	'TURMEQUE (BOYACA)',
	'TUTA (BOYACA)',
	'TUTAZA (BOYACA)',
	'UMBITA (BOYACA)',
	'VENTAQUEMADA (BOYACA)',
	'VIRACACHA (BOYACA)',
	'ZETAQUIRA (BOYACA)',
	'MANIZALES (CALDAS)',
	'AGUADAS (CALDAS)',
	'ANSERMA (CALDAS)',
	'ARANZAZU (CALDAS)',
	'BELALCAZAR (CALDAS)',
	'CHINCHINA (CALDAS)',
	'FILADELFIA (CALDAS)',
	'LA DORADA (CALDAS)',
	'LA MERCED (CALDAS)',
	'MANZANARES (CALDAS)',
	'MARMATO (CALDAS)',
	'MARQUETALIA (CALDAS)',
	'MARULANDA (CALDAS)',
	'NEIRA (CALDAS)',
	'NORCASIA (CALDAS)',
	'PACORA (CALDAS)',
	'PALESTINA (CALDAS)',
	'PENSILVANIA (CALDAS)',
	'RIOSUCIO (CALDAS)',
	'RISARALDA (CALDAS)',
	'SALAMINA (CALDAS)',
	'SAMANA (CALDAS)',
	'SAN JOSE (CALDAS)',
	'SUPIA (CALDAS)',
	'VICTORIA (CALDAS)',
	'VILLAMARIA (CALDAS)',
	'VITERBO (CALDAS)',
	'FLORENCIA (CAQUETA)',
	'ALBANIA (CAQUETA)',
	'BELEN DE LOS ANDAQUIES (CAQUETA)',
	'CARTAGENA DEL CHAIRA (CAQUETA)',
	'CURILLO (CAQUETA)',
	'EL DONCELLO (CAQUETA)',
	'EL PAUJIL (CAQUETA)',
	'LA MONTAÑITA (CAQUETA)',
	'MILAN (CAQUETA)',
	'MORELIA (CAQUETA)',
	'PUERTO RICO (CAQUETA)',
	'SAN JOSE DEL FRAGUA (CAQUETA)',
	'SAN VICENTE DEL CAGUAN (CAQUETA)',
	'SOLANO (CAQUETA)',
	'SOLITA (CAQUETA)',
	'VALPARAISO (CAQUETA)',
	'POPAYAN (CAUCA)',
	'ALMAGUER (CAUCA)',
	'ARGELIA (CAUCA)',
	'BALBOA (CAUCA)',
	'BOLIVAR (CAUCA)',
	'BUENOS AIRES (CAUCA)',
	'CAJIBIO (CAUCA)',
	'CALDONO (CAUCA)',
	'CALOTO (CAUCA)',
	'CORINTO (CAUCA)',
	'EL TAMBO (CAUCA)',
	'FLORENCIA (CAUCA)',
	'GUACHENE (CAUCA)',
	'GUAPI (CAUCA)',
	'INZA (CAUCA)',
	'JAMBALO (CAUCA)',
	'LA SIERRA (CAUCA)',
	'LA VEGA (CAUCA)',
	'LOPEZ (CAUCA)',
	'MERCADERES (CAUCA)',
	'MIRANDA (CAUCA)',
	'MORALES (CAUCA)',
	'PADILLA (CAUCA)',
	'PAEZ (CAUCA)',
	'PATIA (CAUCA)',
	'PIAMONTE (CAUCA)',
	'PIENDAMO (CAUCA)',
	'PUERTO TEJADA (CAUCA)',
	'PURACE (CAUCA)',
	'ROSAS (CAUCA)',
	'SAN SEBASTIAN (CAUCA)',
	'SANTANDER DE QUILICHAO (CAUCA)',
	'SANTA ROSA (CAUCA)',
	'SILVIA (CAUCA)',
	'SOTARA (CAUCA)',
	'SUAREZ (CAUCA)',
	'SUCRE (CAUCA)',
	'TIMBIO (CAUCA)',
	'TIMBIQUI (CAUCA)',
	'TORIBIO (CAUCA)',
	'TOTORO (CAUCA)',
	'VILLA RICA (CAUCA)',
	'VALLEDUPAR (CESAR)',
	'AGUACHICA (CESAR)',
	'AGUSTIN CODAZZI (CESAR)',
	'ASTREA (CESAR)',
	'BECERRIL (CESAR)',
	'BOSCONIA (CESAR)',
	'CHIMICHAGUA (CESAR)',
	'CHIRIGUANA (CESAR)',
	'CURUMANI (CESAR)',
	'EL COPEY (CESAR)',
	'EL PASO (CESAR)',
	'GAMARRA (CESAR)',
	'GONZALEZ (CESAR)',
	'LA GLORIA (CESAR)',
	'LA JAGUA DE IBIRICO (CESAR)',
	'MANAURE (CESAR)',
	'PAILITAS (CESAR)',
	'PELAYA (CESAR)',
	'PUEBLO BELLO (CESAR)',
	'RIO DE ORO (CESAR)',
	'LA PAZ (CESAR)',
	'SAN ALBERTO (CESAR)',
	'SAN DIEGO (CESAR)',
	'SAN MARTIN (CESAR)',
	'TAMALAMEQUE (CESAR)',
	'MONTERIA (CORDOBA)',
	'AYAPEL (CORDOBA)',
	'BUENAVISTA (CORDOBA)',
	'CANALETE (CORDOBA)',
	'CERETE (CORDOBA)',
	'CHIMA (CORDOBA)',
	'CHINU (CORDOBA)',
	'CIENAGA DE ORO (CORDOBA)',
	'COTORRA (CORDOBA)',
	'LA APARTADA (CORDOBA)',
	'LORICA (CORDOBA)',
	'LOS CORDOBAS (CORDOBA)',
	'MOMIL (CORDOBA)',
	'MONTELIBANO (CORDOBA)',
	'MOÑITOS (CORDOBA)',
	'PLANETA RICA (CORDOBA)',
	'PUEBLO NUEVO (CORDOBA)',
	'PUERTO ESCONDIDO (CORDOBA)',
	'PUERTO LIBERTADOR (CORDOBA)',
	'PURISIMA (CORDOBA)',
	'SAHAGUN (CORDOBA)',
	'SAN ANDRES SOTAVENTO (CORDOBA)',
	'SAN ANTERO (CORDOBA)',
	'SAN BERNARDO DEL VIENTO (CORDOBA)',
	'SAN CARLOS (CORDOBA)',
	'SAN PELAYO (CORDOBA)',
	'TIERRALTA (CORDOBA)',
	'VALENCIA (CORDOBA)',
	'AGUA DE DIOS (CUNDINAMARCA)',
	'ALBAN (CUNDINAMARCA)',
	'ANAPOIMA (CUNDINAMARCA)',
	'ANOLAIMA (CUNDINAMARCA)',
	'ARBELAEZ (CUNDINAMARCA)',
	'BELTRAN (CUNDINAMARCA)',
	'BITUIMA (CUNDINAMARCA)',
	'BOJACA (CUNDINAMARCA)',
	'CABRERA (CUNDINAMARCA)',
	'CACHIPAY (CUNDINAMARCA)',
	'CAJICA (CUNDINAMARCA)',
	'CAPARRAPI (CUNDINAMARCA)',
	'CAQUEZA (CUNDINAMARCA)',
	'CARMEN DE CARUPA (CUNDINAMARCA)',
	'CHAGUANI (CUNDINAMARCA)',
	'CHIA (CUNDINAMARCA)',
	'CHIPAQUE (CUNDINAMARCA)',
	'CHOACHI (CUNDINAMARCA)',
	'CHOCONTA (CUNDINAMARCA)',
	'COGUA (CUNDINAMARCA)',
	'COTA (CUNDINAMARCA)',
	'CUCUNUBA (CUNDINAMARCA)',
	'EL COLEGIO (CUNDINAMARCA)',
	'EL PEÑON (CUNDINAMARCA)',
	'EL ROSAL (CUNDINAMARCA)',
	'FACATATIVA (CUNDINAMARCA)',
	'FOMEQUE (CUNDINAMARCA)',
	'FOSCA (CUNDINAMARCA)',
	'FUNZA (CUNDINAMARCA)',
	'FUQUENE (CUNDINAMARCA)',
	'FUSAGASUGA (CUNDINAMARCA)',
	'GACHALA (CUNDINAMARCA)',
	'GACHANCIPA (CUNDINAMARCA)',
	'GACHETA (CUNDINAMARCA)',
	'GAMA (CUNDINAMARCA)',
	'GIRARDOT (CUNDINAMARCA)',
	'GRANADA (CUNDINAMARCA)',
	'GUACHETA (CUNDINAMARCA)',
	'GUADUAS (CUNDINAMARCA)',
	'GUASCA (CUNDINAMARCA)',
	'GUATAQUI (CUNDINAMARCA)',
	'GUATAVITA (CUNDINAMARCA)',
	'GUAYABAL DE SIQUIMA (CUNDINAMARCA)',
	'GUAYABETAL (CUNDINAMARCA)',
	'GUTIERREZ (CUNDINAMARCA)',
	'JERUSALEN (CUNDINAMARCA)',
	'JUNIN (CUNDINAMARCA)',
	'LA CALERA (CUNDINAMARCA)',
	'LA MESA (CUNDINAMARCA)',
	'LA PALMA (CUNDINAMARCA)',
	'LA PEÑA (CUNDINAMARCA)',
	'LA VEGA (CUNDINAMARCA)',
	'LENGUAZAQUE (CUNDINAMARCA)',
	'MACHETA (CUNDINAMARCA)',
	'MADRID (CUNDINAMARCA)',
	'MANTA (CUNDINAMARCA)',
	'MEDINA (CUNDINAMARCA)',
	'MOSQUERA (CUNDINAMARCA)',
	'NARIÑO (CUNDINAMARCA)',
	'NEMOCON (CUNDINAMARCA)',
	'NILO (CUNDINAMARCA)',
	'NIMAIMA (CUNDINAMARCA)',
	'NOCAIMA (CUNDINAMARCA)',
	'VENECIA (CUNDINAMARCA)',
	'PACHO (CUNDINAMARCA)',
	'PAIME (CUNDINAMARCA)',
	'PANDI (CUNDINAMARCA)',
	'PARATEBUENO (CUNDINAMARCA)',
	'PASCA (CUNDINAMARCA)',
	'PUERTO SALGAR (CUNDINAMARCA)',
	'PULI (CUNDINAMARCA)',
	'QUEBRADANEGRA (CUNDINAMARCA)',
	'QUETAME (CUNDINAMARCA)',
	'QUIPILE (CUNDINAMARCA)',
	'APULO (CUNDINAMARCA)',
	'RICAURTE (CUNDINAMARCA)',
	'SAN ANTONIO DEL TEQUENDAMA (CUNDINAMARCA)',
	'SAN BERNARDO (CUNDINAMARCA)',
	'SAN CAYETANO (CUNDINAMARCA)',
	'SAN FRANCISCO (CUNDINAMARCA)',
	'SAN JUAN DE RIO SECO (CUNDINAMARCA)',
	'SASAIMA (CUNDINAMARCA)',
	'SESQUILE (CUNDINAMARCA)',
	'SIBATE (CUNDINAMARCA)',
	'SILVANIA (CUNDINAMARCA)',
	'SIMIJACA (CUNDINAMARCA)',
	'SOACHA (CUNDINAMARCA)',
	'SOPO (CUNDINAMARCA)',
	'SUBACHOQUE (CUNDINAMARCA)',
	'SUESCA (CUNDINAMARCA)',
	'SUPATA (CUNDINAMARCA)',
	'SUSA (CUNDINAMARCA)',
	'SUTATAUSA (CUNDINAMARCA)',
	'TABIO (CUNDINAMARCA)',
	'TAUSA (CUNDINAMARCA)',
	'TENA (CUNDINAMARCA)',
	'TENJO (CUNDINAMARCA)',
	'TIBACUY (CUNDINAMARCA)',
	'TIBIRITA (CUNDINAMARCA)',
	'TOCAIMA (CUNDINAMARCA)',
	'TOCANCIPA (CUNDINAMARCA)',
	'TOPAIPI (CUNDINAMARCA)',
	'UBALA (CUNDINAMARCA)',
	'UBAQUE (CUNDINAMARCA)',
	'VILLA DE SAN DIEGO DE UBATE (CUNDINAMARCA)',
	'UNE (CUNDINAMARCA)',
	'UTICA (CUNDINAMARCA)',
	'VERGARA (CUNDINAMARCA)',
	'VIANI (CUNDINAMARCA)',
	'VILLAGOMEZ (CUNDINAMARCA)',
	'VILLAPINZON (CUNDINAMARCA)',
	'VILLETA (CUNDINAMARCA)',
	'VIOTA (CUNDINAMARCA)',
	'YACOPI (CUNDINAMARCA)',
	'ZIPACON (CUNDINAMARCA)',
	'ZIPAQUIRA (CUNDINAMARCA)',
	'QUIBDO (CHOCO)',
	'ACANDI (CHOCO)',
	'ALTO BAUDO (CHOCO)',
	'ATRATO (CHOCO)',
	'BAGADO (CHOCO)',
	'BAHIA SOLANO (CHOCO)',
	'BAJO BAUDO (CHOCO)',
	'BOJAYA (CHOCO)',
	'EL CANTON DEL SAN PABLO (CHOCO)',
	'CARMEN DEL DARIEN (CHOCO)',
	'CERTEGUI (CHOCO)',
	'CONDOTO (CHOCO)',
	'EL CARMEN DE ATRATO (CHOCO)',
	'EL LITORAL DEL SAN JUAN (CHOCO)',
	'ISTMINA (CHOCO)',
	'JURADO (CHOCO)',
	'LLORO (CHOCO)',
	'MEDIO ATRATO (CHOCO)',
	'MEDIO BAUDO (CHOCO)',
	'MEDIO SAN JUAN (CHOCO)',
	'NOVITA (CHOCO)',
	'NUQUI (CHOCO)',
	'RIO IRO (CHOCO)',
	'RIO QUITO (CHOCO)',
	'RIOSUCIO (CHOCO)',
	'SAN JOSE DEL PALMAR (CHOCO)',
	'SIPI (CHOCO)',
	'TADO (CHOCO)',
	'UNGUIA (CHOCO)',
	'UNION PANAMERICANA (CHOCO)',
	'NEIVA (HUILA)',
	'ACEVEDO (HUILA)',
	'AGRADO (HUILA)',
	'AIPE (HUILA)',
	'ALGECIRAS (HUILA)',
	'ALTAMIRA (HUILA)',
	'BARAYA (HUILA)',
	'CAMPOALEGRE (HUILA)',
	'COLOMBIA (HUILA)',
	'ELIAS (HUILA)',
	'GARZON (HUILA)',
	'GIGANTE (HUILA)',
	'GUADALUPE (HUILA)',
	'HOBO (HUILA)',
	'IQUIRA (HUILA)',
	'ISNOS (HUILA)',
	'LA ARGENTINA (HUILA)',
	'LA PLATA (HUILA)',
	'NATAGA (HUILA)',
	'OPORAPA (HUILA)',
	'PAICOL (HUILA)',
	'PALERMO (HUILA)',
	'PALESTINA (HUILA)',
	'PITAL (HUILA)',
	'PITALITO (HUILA)',
	'RIVERA (HUILA)',
	'SALADOBLANCO (HUILA)',
	'SAN AGUSTIN (HUILA)',
	'SANTA MARIA (HUILA)',
	'SUAZA (HUILA)',
	'TARQUI (HUILA)',
	'TESALIA (HUILA)',
	'TELLO (HUILA)',
	'TERUEL (HUILA)',
	'TIMANA (HUILA)',
	'VILLAVIEJA (HUILA)',
	'YAGUARA (HUILA)',
	'RIOHACHA (LA GUAJIRA)',
	'ALBANIA (LA GUAJIRA)',
	'BARRANCAS (LA GUAJIRA)',
	'DIBULLA (LA GUAJIRA)',
	'DISTRACCION (LA GUAJIRA)',
	'EL MOLINO (LA GUAJIRA)',
	'FONSECA (LA GUAJIRA)',
	'HATONUEVO (LA GUAJIRA)',
	'LA JAGUA DEL PILAR (LA GUAJIRA)',
	'MAICAO (LA GUAJIRA)',
	'MANAURE (LA GUAJIRA)',
	'SAN JUAN DEL CESAR (LA GUAJIRA)',
	'URIBIA (LA GUAJIRA)',
	'URUMITA (LA GUAJIRA)',
	'VILLANUEVA (LA GUAJIRA)',	
	'ALGARROBO (MAGDALENA)',
	'ARACATACA (MAGDALENA)',
	'ARIGUANI (MAGDALENA)',
	'CERRO SAN ANTONIO (MAGDALENA)',
	'CHIBOLO (MAGDALENA)',
	'CIENAGA (MAGDALENA)',
	'CONCORDIA (MAGDALENA)',
	'EL BANCO (MAGDALENA)',
	'EL PIÑON (MAGDALENA)',
	'EL RETEN (MAGDALENA)',
	'FUNDACION (MAGDALENA)',
	'GUAMAL (MAGDALENA)',
	'NUEVA GRANADA (MAGDALENA)',
	'PEDRAZA (MAGDALENA)',
	'PIJIÑO DEL CARMEN (MAGDALENA)',
	'PIVIJAY (MAGDALENA)',
	'PLATO (MAGDALENA)',
	'PUEBLOVIEJO (MAGDALENA)',
	'REMOLINO (MAGDALENA)',
	'SABANAS DE SAN ANGEL (MAGDALENA)',
	'SALAMINA (MAGDALENA)',
	'SAN SEBASTIAN DE BUENAVISTA (MAGDALENA)',
	'SAN ZENON (MAGDALENA)',
	'SANTA ANA (MAGDALENA)',
	'SANTA BARBARA DE PINTO (MAGDALENA)',
	'SITIONUEVO (MAGDALENA)',
	'TENERIFE (MAGDALENA)',
	'ZAPAYAN (MAGDALENA)',
	'ZONA BANANERA (MAGDALENA)',
	'VILLAVICENCIO (META)',
	'ACACIAS (META)',
	'BARRANCA DE UPIA (META)',
	'CABUYARO (META)',
	'CASTILLA LA NUEVA (META)',
	'CUBARRAL (META)',
	'CUMARAL (META)',
	'EL CALVARIO (META)',
	'EL CASTILLO (META)',
	'EL DORADO (META)',
	'FUENTE DE ORO (META)',
	'GRANADA (META)',
	'GUAMAL (META)',
	'MAPIRIPAN (META)',
	'MESETAS (META)',
	'LA MACARENA (META)',
	'URIBE (META)',
	'LEJANIAS (META)',
	'PUERTO CONCORDIA (META)',
	'PUERTO GAITAN (META)',
	'PUERTO LOPEZ (META)',
	'PUERTO LLERAS (META)',
	'PUERTO RICO (META)',
	'RESTREPO (META)',
	'SAN CARLOS DE GUAROA (META)',
	'SAN JUAN DE ARAMA (META)',
	'SAN JUANITO (META)',
	'SAN MARTIN (META)',
	'VISTAHERMOSA (META)',
	'PASTO (NARIÑO)',
	'ALBAN (NARIÑO)',
	'ALDANA (NARIÑO)',
	'ANCUYA (NARIÑO)',
	'ARBOLEDA (NARIÑO)',
	'BARBACOAS (NARIÑO)',
	'BELEN (NARIÑO)',
	'BUESACO (NARIÑO)',
	'COLON (NARIÑO)',
	'CONSACA (NARIÑO)',
	'CONTADERO (NARIÑO)',
	'CORDOBA (NARIÑO)',
	'CUASPUD (NARIÑO)',
	'CUMBAL (NARIÑO)',
	'CUMBITARA (NARIÑO)',
	'CHACHAGsI (NARIÑO)',
	'EL CHARCO (NARIÑO)',
	'EL PEÑOL (NARIÑO)',
	'EL ROSARIO (NARIÑO)',
	'EL TABLON DE GOMEZ (NARIÑO)',
	'EL TAMBO (NARIÑO)',
	'FUNES (NARIÑO)',
	'GUACHUCAL (NARIÑO)',
	'GUAITARILLA (NARIÑO)',
	'GUALMATAN (NARIÑO)',
	'ILES (NARIÑO)',
	'IMUES (NARIÑO)',
	'IPIALES (NARIÑO)',
	'LA CRUZ (NARIÑO)',
	'LA FLORIDA (NARIÑO)',
	'LA LLANADA (NARIÑO)',
	'LA TOLA (NARIÑO)',
	'LA UNION (NARIÑO)',
	'LEIVA (NARIÑO)',
	'LINARES (NARIÑO)',
	'LOS ANDES (NARIÑO)',
	'MAGsI (NARIÑO)',
	'MALLAMA (NARIÑO)',
	'MOSQUERA (NARIÑO)',
	'NARIÑO (NARIÑO)',
	'OLAYA HERRERA (NARIÑO)',
	'OSPINA (NARIÑO)',
	'FRANCISCO PIZARRO (NARIÑO)',
	'POLICARPA (NARIÑO)',
	'POTOSI (NARIÑO)',
	'PROVIDENCIA (NARIÑO)',
	'PUERRES (NARIÑO)',
	'PUPIALES (NARIÑO)',
	'RICAURTE (NARIÑO)',
	'ROBERTO PAYAN (NARIÑO)',
	'SAMANIEGO (NARIÑO)',
	'SANDONA (NARIÑO)',
	'SAN BERNARDO (NARIÑO)',
	'SAN LORENZO (NARIÑO)',
	'SAN PABLO (NARIÑO)',
	'SAN PEDRO DE CARTAGO (NARIÑO)',
	'SANTA BARBARA (NARIÑO)',
	'SANTACRUZ (NARIÑO)',
	'SAPUYES (NARIÑO)',
	'TAMINANGO (NARIÑO)',
	'TANGUA (NARIÑO)',
	'SAN ANDRES DE TUMACO (NARIÑO)',
	'TUQUERRES (NARIÑO)',
	'YACUANQUER (NARIÑO)',	
	'ABREGO (N. DE SANTANDER)',
	'ARBOLEDAS (N. DE SANTANDER)',
	'BOCHALEMA (N. DE SANTANDER)',
	'BUCARASICA (N. DE SANTANDER)',
	'CACOTA (N. DE SANTANDER)',
	'CACHIRA (N. DE SANTANDER)',
	'CHINACOTA (N. DE SANTANDER)',
	'CHITAGA (N. DE SANTANDER)',
	'CONVENCION (N. DE SANTANDER)',
	'CUCUTILLA (N. DE SANTANDER)',
	'DURANIA (N. DE SANTANDER)',
	'EL CARMEN (N. DE SANTANDER)',
	'EL TARRA (N. DE SANTANDER)',
	'EL ZULIA (N. DE SANTANDER)',
	'GRAMALOTE (N. DE SANTANDER)',
	'HACARI (N. DE SANTANDER)',
	'HERRAN (N. DE SANTANDER)',
	'LABATECA (N. DE SANTANDER)',
	'LA ESPERANZA (N. DE SANTANDER)',
	'LA PLAYA (N. DE SANTANDER)',
	'LOS PATIOS (N. DE SANTANDER)',
	'LOURDES (N. DE SANTANDER)',
	'MUTISCUA (N. DE SANTANDER)',
	'OCAÑA (N. DE SANTANDER)',
	'PAMPLONA (N. DE SANTANDER)',
	'PAMPLONITA (N. DE SANTANDER)',
	'PUERTO SANTANDER (N. DE SANTANDER)',
	'RAGONVALIA (N. DE SANTANDER)',
	'SALAZAR (N. DE SANTANDER)',
	'SAN CALIXTO (N. DE SANTANDER)',
	'SAN CAYETANO (N. DE SANTANDER)',
	'SANTIAGO (N. DE SANTANDER)',
	'SARDINATA (N. DE SANTANDER)',
	'SILOS (N. DE SANTANDER)',
	'TEORAMA (N. DE SANTANDER)',
	'TIBU (N. DE SANTANDER)',
	'TOLEDO (N. DE SANTANDER)',
	'VILLA CARO (N. DE SANTANDER)',
	'VILLA DEL ROSARIO (N. DE SANTANDER)',
	'ARMENIA (QUINDIO)',
	'BUENAVISTA (QUINDIO)',
	'CALARCA (QUINDIO)',
	'CIRCASIA (QUINDIO)',
	'CORDOBA (QUINDIO)',
	'FILANDIA (QUINDIO)',
	'GENOVA (QUINDIO)',
	'LA TEBAIDA (QUINDIO)',
	'MONTENEGRO (QUINDIO)',
	'PIJAO (QUINDIO)',
	'QUIMBAYA (QUINDIO)',
	'SALENTO (QUINDIO)',	
	'APIA (RISARALDA)',
	'BALBOA (RISARALDA)',
	'BELEN DE UMBRIA (RISARALDA)',
	'DOSQUEBRADAS (RISARALDA)',
	'GUATICA (RISARALDA)',
	'LA CELIA (RISARALDA)',
	'LA VIRGINIA (RISARALDA)',
	'MARSELLA (RISARALDA)',
	'MISTRATO (RISARALDA)',
	'PUEBLO RICO (RISARALDA)',
	'QUINCHIA (RISARALDA)',
	'SANTA ROSA DE CABAL (RISARALDA)',
	'SANTUARIO (RISARALDA)',
	'BUCARAMANGA (SANTANDER)',
	'AGUADA (SANTANDER)',
	'ALBANIA (SANTANDER)',
	'ARATOCA (SANTANDER)',
	'BARBOSA (SANTANDER)',
	'BARICHARA (SANTANDER)',
	'BARRANCABERMEJA (SANTANDER)',
	'BETULIA (SANTANDER)',
	'BOLIVAR (SANTANDER)',
	'CABRERA (SANTANDER)',
	'CALIFORNIA (SANTANDER)',
	'CAPITANEJO (SANTANDER)',
	'CARCASI (SANTANDER)',
	'CEPITA (SANTANDER)',
	'CERRITO (SANTANDER)',
	'CHARALA (SANTANDER)',
	'CHARTA (SANTANDER)',
	'CHIMA (SANTANDER)',
	'CHIPATA (SANTANDER)',
	'CIMITARRA (SANTANDER)',
	'CONCEPCION (SANTANDER)',
	'CONFINES (SANTANDER)',
	'CONTRATACION (SANTANDER)',
	'COROMORO (SANTANDER)',
	'CURITI (SANTANDER)',
	'EL CARMEN DE CHUCURI (SANTANDER)',
	'EL GUACAMAYO (SANTANDER)',
	'EL PEÑON (SANTANDER)',
	'EL PLAYON (SANTANDER)',
	'ENCINO (SANTANDER)',
	'ENCISO (SANTANDER)',
	'FLORIAN (SANTANDER)',
	'FLORIDABLANCA (SANTANDER)',
	'GALAN (SANTANDER)',
	'GAMBITA (SANTANDER)',
	'GIRON (SANTANDER)',
	'GUACA (SANTANDER)',
	'GUADALUPE (SANTANDER)',
	'GUAPOTA (SANTANDER)',
	'GUAVATA (SANTANDER)',
	'GsEPSA (SANTANDER)',
	'HATO (SANTANDER)',
	'JESUS MARIA (SANTANDER)',
	'JORDAN (SANTANDER)',
	'LA BELLEZA (SANTANDER)',
	'LANDAZURI (SANTANDER)',
	'LA PAZ (SANTANDER)',
	'LEBRIJA (SANTANDER)',
	'LOS SANTOS (SANTANDER)',
	'MACARAVITA (SANTANDER)',
	'MALAGA (SANTANDER)',
	'MATANZA (SANTANDER)',
	'MOGOTES (SANTANDER)',
	'MOLAGAVITA (SANTANDER)',
	'OCAMONTE (SANTANDER)',
	'OIBA (SANTANDER)',
	'ONZAGA (SANTANDER)',
	'PALMAR (SANTANDER)',
	'PALMAS DEL SOCORRO (SANTANDER)',
	'PARAMO (SANTANDER)',
	'PIEDECUESTA (SANTANDER)',
	'PINCHOTE (SANTANDER)',
	'PUENTE NACIONAL (SANTANDER)',
	'PUERTO PARRA (SANTANDER)',
	'PUERTO WILCHES (SANTANDER)',
	'RIONEGRO (SANTANDER)',
	'SABANA DE TORRES (SANTANDER)',
	'SAN ANDRES (SANTANDER)',
	'SAN BENITO (SANTANDER)',
	'SAN GIL (SANTANDER)',
	'SAN JOAQUIN (SANTANDER)',
	'SAN JOSE DE MIRANDA (SANTANDER)',
	'SAN MIGUEL (SANTANDER)',
	'SAN VICENTE DE CHUCURI (SANTANDER)',
	'SANTA BARBARA (SANTANDER)',
	'SANTA HELENA DEL OPON (SANTANDER)',
	'SIMACOTA (SANTANDER)',
	'SOCORRO (SANTANDER)',
	'SUAITA (SANTANDER)',
	'SUCRE (SANTANDER)',
	'SURATA (SANTANDER)',
	'TONA (SANTANDER)',
	'VALLE DE SAN JOSE (SANTANDER)',
	'VELEZ (SANTANDER)',
	'VETAS (SANTANDER)',
	'VILLANUEVA (SANTANDER)',
	'ZAPATOCA (SANTANDER)',
	'SINCELEJO (SUCRE)',
	'BUENAVISTA (SUCRE)',
	'CAIMITO (SUCRE)',
	'COLOSO (SUCRE)',
	'COROZAL (SUCRE)',
	'COVEÑAS (SUCRE)',
	'CHALAN (SUCRE)',
	'EL ROBLE (SUCRE)',
	'GALERAS (SUCRE)',
	'GUARANDA (SUCRE)',
	'LA UNION (SUCRE)',
	'LOS PALMITOS (SUCRE)',
	'MAJAGUAL (SUCRE)',
	'MORROA (SUCRE)',
	'OVEJAS (SUCRE)',
	'PALMITO (SUCRE)',
	'SAMPUES (SUCRE)',
	'SAN BENITO ABAD (SUCRE)',
	'SAN JUAN DE BETULIA (SUCRE)',
	'SAN MARCOS (SUCRE)',
	'SAN ONOFRE (SUCRE)',
	'SAN PEDRO (SUCRE)',
	'SAN LUIS DE SINCE (SUCRE)',
	'SUCRE (SUCRE)',
	'SANTIAGO DE TOLU (SUCRE)',
	'TOLU VIEJO (SUCRE)',
	'IBAGUE (TOLIMA)',
	'ALPUJARRA (TOLIMA)',
	'ALVARADO (TOLIMA)',
	'AMBALEMA (TOLIMA)',
	'ANZOATEGUI (TOLIMA)',
	'ARMERO (TOLIMA)',
	'ATACO (TOLIMA)',
	'CAJAMARCA (TOLIMA)',
	'CARMEN DE APICALA (TOLIMA)',
	'CASABIANCA (TOLIMA)',
	'CHAPARRAL (TOLIMA)',
	'COELLO (TOLIMA)',
	'COYAIMA (TOLIMA)',
	'CUNDAY (TOLIMA)',
	'DOLORES (TOLIMA)',
	'ESPINAL (TOLIMA)',
	'FALAN (TOLIMA)',
	'FLANDES (TOLIMA)',
	'FRESNO (TOLIMA)',
	'GUAMO (TOLIMA)',
	'HERVEO (TOLIMA)',
	'HONDA (TOLIMA)',
	'ICONONZO (TOLIMA)',
	'LERIDA (TOLIMA)',
	'LIBANO (TOLIMA)',
	'MARIQUITA (TOLIMA)',
	'MELGAR (TOLIMA)',
	'MURILLO (TOLIMA)',
	'NATAGAIMA (TOLIMA)',
	'ORTEGA (TOLIMA)',
	'PALOCABILDO (TOLIMA)',
	'PIEDRAS (TOLIMA)',
	'PLANADAS (TOLIMA)',
	'PRADO (TOLIMA)',
	'PURIFICACION (TOLIMA)',
	'RIOBLANCO (TOLIMA)',
	'RONCESVALLES (TOLIMA)',
	'ROVIRA (TOLIMA)',
	'SALDAÑA (TOLIMA)',
	'SAN ANTONIO (TOLIMA)',
	'SAN LUIS (TOLIMA)',
	'SANTA ISABEL (TOLIMA)',
	'SUAREZ (TOLIMA)',
	'VALLE DE SAN JUAN (TOLIMA)',
	'VENADILLO (TOLIMA)',
	'VILLAHERMOSA (TOLIMA)',
	'VILLARRICA (TOLIMA)',	
	'ALCALA (VALLE DEL CAUCA)',
	'ANDALUCIA (VALLE DEL CAUCA)',
	'ANSERMANUEVO (VALLE DEL CAUCA)',
	'ARGELIA (VALLE DEL CAUCA)',
	'BOLIVAR (VALLE DEL CAUCA)',
	'BUENAVENTURA (VALLE DEL CAUCA)',
	'GUADALAJARA DE BUGA (VALLE DEL CAUCA)',
	'BUGALAGRANDE (VALLE DEL CAUCA)',
	'CAICEDONIA (VALLE DEL CAUCA)',
	'CALIMA (VALLE DEL CAUCA)',
	'CANDELARIA (VALLE DEL CAUCA)',
	'CARTAGO (VALLE DEL CAUCA)',
	'DAGUA (VALLE DEL CAUCA)',
	'EL AGUILA (VALLE DEL CAUCA)',
	'EL CAIRO (VALLE DEL CAUCA)',
	'EL CERRITO (VALLE DEL CAUCA)',
	'EL DOVIO (VALLE DEL CAUCA)',
	'FLORIDA (VALLE DEL CAUCA)',
	'GINEBRA (VALLE DEL CAUCA)',
	'GUACARI (VALLE DEL CAUCA)',
	'JAMUNDI (VALLE DEL CAUCA)',
	'LA CUMBRE (VALLE DEL CAUCA)',
	'LA UNION (VALLE DEL CAUCA)',
	'LA VICTORIA (VALLE DEL CAUCA)',
	'OBANDO (VALLE DEL CAUCA)',
	'PALMIRA (VALLE DEL CAUCA)',
	'PRADERA (VALLE DEL CAUCA)',
	'RESTREPO (VALLE DEL CAUCA)',
	'RIOFRIO (VALLE DEL CAUCA)',
	'ROLDANILLO (VALLE DEL CAUCA)',
	'SAN PEDRO (VALLE DEL CAUCA)',
	'SEVILLA (VALLE DEL CAUCA)',
	'TORO (VALLE DEL CAUCA)',
	'TRUJILLO (VALLE DEL CAUCA)',
	'TULUA (VALLE DEL CAUCA)',
	'ULLOA (VALLE DEL CAUCA)',
	'VERSALLES (VALLE DEL CAUCA)',
	'VIJES (VALLE DEL CAUCA)',
	'YOTOCO (VALLE DEL CAUCA)',
	'YUMBO (VALLE DEL CAUCA)',
	'ZARZAL (VALLE DEL CAUCA)',
	'ARAUCA (ARAUCA)',
	'ARAUQUITA (ARAUCA)',
	'CRAVO NORTE (ARAUCA)',
	'FORTUL (ARAUCA)',
	'PUERTO RONDON (ARAUCA)',
	'SARAVENA (ARAUCA)',
	'TAME (ARAUCA)',
	'YOPAL (CASANARE)',
	'AGUAZUL (CASANARE)',
	'CHAMEZA (CASANARE)',
	'HATO COROZAL (CASANARE)',
	'LA SALINA (CASANARE)',
	'MANI (CASANARE)',
	'MONTERREY (CASANARE)',
	'NUNCHIA (CASANARE)',
	'OROCUE (CASANARE)',
	'PAZ DE ARIPORO (CASANARE)',
	'PORE (CASANARE)',
	'RECETOR (CASANARE)',
	'SABANALARGA (CASANARE)',
	'SACAMA (CASANARE)',
	'SAN LUIS DE PALENQUE (CASANARE)',
	'TAMARA (CASANARE)',
	'TAURAMENA (CASANARE)',
	'TRINIDAD (CASANARE)',
	'VILLANUEVA (CASANARE)',
	'MOCOA (PUTUMAYO)',
	'COLON (PUTUMAYO)',
	'ORITO (PUTUMAYO)',
	'PUERTO ASIS (PUTUMAYO)',
	'PUERTO CAICEDO (PUTUMAYO)',
	'PUERTO GUZMAN (PUTUMAYO)',
	'LEGUIZAMO (PUTUMAYO)',
	'SIBUNDOY (PUTUMAYO)',
	'SAN FRANCISCO (PUTUMAYO)',
	'SAN MIGUEL (PUTUMAYO)',
	'SANTIAGO (PUTUMAYO)',
	'VALLE DEL GUAMUEZ (PUTUMAYO)',
	'VILLAGARZON (PUTUMAYO)',
	'SAN ANDRES (SAN ANDRES)',
	'PROVIDENCIA (SAN ANDRES)',
	'LETICIA (AMAZONAS)',
	'EL ENCANTO (AMAZONAS)',
	'LA CHORRERA (AMAZONAS)',
	'LA PEDRERA (AMAZONAS)',
	'LA VICTORIA (AMAZONAS)',
	'MIRITI - PARANA (AMAZONAS)',
	'PUERTO ALEGRIA (AMAZONAS)',
	'PUERTO ARICA (AMAZONAS)',
	'PUERTO NARIÑO (AMAZONAS)',
	'PUERTO SANTANDER (AMAZONAS)',
	'TARAPACA (AMAZONAS)',
	'INIRIDA (GUAINIA)',
	'BARRANCO MINAS (GUAINIA)',
	'MAPIRIPANA (GUAINIA)',
	'SAN FELIPE (GUAINIA)',
	'PUERTO COLOMBIA (GUAINIA)',
	'LA GUADALUPE (GUAINIA)',
	'CACAHUAL (GUAINIA)',
	'PANA PANA (GUAINIA)',
	'MORICHAL (GUAINIA)',
	'SAN JOSE DEL GUAVIARE (GUAVIARE)',
	'CALAMAR (GUAVIARE)',
	'EL RETORNO (GUAVIARE)',
	'MIRAFLORES (GUAVIARE)',
	'MITU (VAUPES)',
	'CARURU (VAUPES)',
	'PACOA (VAUPES)',
	'TARAIRA (VAUPES)',
	'PAPUNAUA (VAUPES)',
	'YAVARATE (VAUPES)',
	'PUERTO CARREÑO (VICHADA)',
	'LA PRIMAVERA (VICHADA)',
	'SANTA ROSALIA (VICHADA)',
	'CUMARIBO (VICHADA)',
]