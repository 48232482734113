import React, { useState } from 'react'
import { message } from 'antd'
import { Button, InputGroup, Form } from 'react-bootstrap'

import { handleError } from '../../../helpers'
import { storeModelRanking } from '../services'

export default function ModelRankingForm({ type, id, reload }) {
	const [selectedRanking, setSelectedRanking] = useState('')

	const onSubmit = () => {
		if(!selectedRanking) 
			message.warn('Debe seleccionar un cargo')

		selectedRanking && storeModelRanking({
			model_type: `App\\Models\\${type}`,
			model_id: id,
			ranking: selectedRanking
		})
			.then(() => {
				message.success('Cargo asociado exitosamente')
				setSelectedRanking([])
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<InputGroup className="mt-2">
				<Form.Group>
					<Form.Control
						as="select"
						value={selectedRanking}
						onChange={e => setSelectedRanking(e.target.value)}
					>
						<option value="">::Seleccionar Cargo::</option>
						<option value="agent">Asesor Comercial</option>
						<option value="store_leader">Líder de Tienda</option>
						<option value="coordinador">Coordinador</option>
						<option value="jefe_regional">Jefe Regional</option>
						<option value="trade">Trade</option>
						<option value="kam">KAM</option>
						<option value="director">Director</option>
						<option value="admin">Administrador</option>
					</Form.Control>
				</Form.Group>
				<InputGroup.Append>
					<Button 
						variant="primary" 
						onClick={onSubmit}
						style={{ height: '32px', padding: '0px 5px' }}
					>
						Asociar Cargo
					</Button>
				</InputGroup.Append>
			</InputGroup>
		</React.Fragment>
	)
}