import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faLockOpen, faPowerOff, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import Pagination from '../../../components/Pagination'
import ModelStoresModal from '../../Stores/partials/ModelStoresModal'
import { deleteNotification, updateNotification } from '../services'
import { handleError } from '../../../helpers'

export default function NotificationsTable({ notifications, reload, pagination, setPagination }) {
	const user = useSelector(state => state.auth.user)
	const [modelStoresModal, setModelStoresModal] = useState(null)

	const handleSwitch = record => {
		updateNotification(record.id, {
			ended_at: record.ended_at ? '' : moment().format('YYYY-MM-DD HH:mm')
		})
			.then(() => {
				message.success('Estado actualizado exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleDelete = id => {
		deleteNotification(id)
			.then(() => {
				message.success('Notificación eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Tipo',
			dataIndex: 'level',
			render: t => <Badge color={t}><FontAwesomeIcon icon={faBell} /></Badge>
		},
		{
			title: 'Notificación',
			dataIndex: 'body',
		},
		{
			title: 'Estado',
			dataIndex: 'ended_at',
			render: t => t ? <Badge color="dark">Finalizada</Badge> : <Badge color="info">Activa</Badge>
		},
		{
			title: 'Finalizada El',
			dataIndex: 'ended_at',
			render: t => t ? moment(t).format('YYYY-MM-DD HH:mm') : '-'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title={record.ended_at ? 'Activar' : 'Inactivar'} placement="bottom">
						<Popconfirm
							title={record.ended_at ? 'Activar notificación' : 'Inactivar notificación'}
							okText="Confirmar"
							cancelText="Cancelar"
							onConfirm={() => handleSwitch(record)}
						>
							<FontAwesomeIcon className="text-link" icon={faPowerOff} />
						</Popconfirm>
					</Tooltip>
					<Divider type="vertical" />					
					<Tooltip title="Tiendas Permitidas">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faLockOpen} 
							onClick={()=>setModelStoresModal({
								id: record.id,
								type: "Notification",
							})}
						/>
					</Tooltip>
					{ user.ranking === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Tooltip title="Eliminar" placement="bottom">
								<Popconfirm
									title="¿Desea eliminar la notificación"
									okText="Eliminar"
									cancelText="Cancelar"
									onConfirm={() => handleDelete(record.id)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faTrash} />
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={notifications}
				rowKey={record => record.id}
				columns={columns}
				pagination={false}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			<ModelStoresModal 
				visible={modelStoresModal ? true : false}
				onClose={() => setModelStoresModal(null)}
				{...modelStoresModal}
			/>
		</React.Fragment>
	)
}