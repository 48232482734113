import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { Spinner } from 'reactstrap'

import { getCompetitors } from '../../Competitors/services'
import { handleError } from '../../../helpers'

export default function CompetitorReferenceForm({ competitorReference, register, errors }) {
	const [competitors, setCompetitors] = useState(null)

	useEffect(() => {
		!competitors && getCompetitors()
			.then(res => setCompetitors(res.data.data))
			.catch(error => handleError(error))
	}, [ competitors ])

	if(!competitors) return <Spinner />

	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Competidor</Form.Label>
				<Form.Control
					as="select"
					{...register('competitor_id', { required: true })}
					defaultValue={competitorReference && competitorReference.competitor_id}
				>
					<option value="">:: Selecione una opción ::</option>
					{ competitors.map( competitor => (
						<option key={competitor.id} value={competitor.id}>
							{competitor.name}
						</option>
					)) }
				</Form.Control>
			</Form.Group>
			<Form.Group>
				<Form.Label>Nombre <span className="text-danger">*</span></Form.Label>
				<Form.Control 
					{...register("name", {required: true})} 
					defaultValue={competitorReference && competitorReference.name} 
				/>
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}