import React from 'react'
import { useSelector } from 'react-redux'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

import { deleteCheckin } from '../services'
import { handleError } from '../../../helpers'

export default function CheckinsTable({ checkins, reload, pagination, setPagination }) {
	const user = useSelector(state => state.auth.user)

	const handleDelete = id => {
		deleteCheckin(id)
			.then(() => {
				message.success('Check In eliminado exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Categoría',
			dataIndex: 'checkin_type',
		},
		{
			title: 'Asesor',
			dataIndex: 'user',
			render: t => t.name
		},
		{
			title: 'Tienda',
			dataIndex: 'store',
			render: t => `(${t.code}) ${t.name}`
		},
		{
			title: 'Fecha de Visita',
			dataIndex: 'visit',
			render: t => t ? moment(t.schedule_at).format('YYYY-MMM-DD hh:mm a') : <em>Sin visita asociada</em>
		},
		{
			title: 'Fecha de Check In',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MMM-DD hh:mm a')
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Ver ubicación">
						<a href={`https://www.google.com/maps/search/?api=1&query=${record.latitude},${record.longitude}`} target="_blank" rel="noreferrer">
							<FontAwesomeIcon 
								icon={faMapMarker}
								className="text-link"
							/>
						</a>
					</Tooltip>
					{ user.ranking === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm
								title="¿Segura desea proceder?"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDelete(record.id)}
							>
								<FontAwesomeIcon icon={faTrash} className="text-link" />
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				columns={columns}
				dataSource={checkins}
				rowKey={record => record.id}
				locale={{ emptyText: <Empty description='No se encontraron registros' /> }}
				pagination={false}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
		</React.Fragment>
	)
}