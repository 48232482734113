import React from 'react'
import { Form } from 'react-bootstrap'

export default function MetricTemplateForm({ metricTemplate, register, errors }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre de Métrica <span className="text-danger">*</span></Form.Label>
				<Form.Control 
					{...register("name", {required: true})} 
					defaultValue={metricTemplate && metricTemplate.name} 
				/>
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Descripción (opcional)</Form.Label>
				<Form.Control 
					{...register("description")} 
					defaultValue={metricTemplate && metricTemplate.description} 
				/>
			</Form.Group>
			<Form.Group>
				<Form.Control as="select" {...register('numeric',{required:true})} defaultValue={metricTemplate && metricTemplate.numeric}>
					<option value="1">Solo números</option>
					<option value="0">Alfa numérico</option>
				</Form.Control>
			</Form.Group>
			<Form.Group>
				<Form.Control as="select" {...register('active',{required:true})} defaultValue={metricTemplate && metricTemplate.active}>
					<option value="1">Activo</option>
					<option value="0">Inactivo</option>
				</Form.Control>
			</Form.Group>
		</React.Fragment>
	)
}