import React from 'react'
import { Form } from 'react-bootstrap'

export default function CheckinTypeForm({ ticketCategory, register, errors }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre de Categoría de Incidencia <span className="text-danger">*</span></Form.Label>
				<Form.Control 
					{...register("name", {required: true})} 
					defaultValue={ticketCategory && ticketCategory.name} 
				/>
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}