import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import VisitForm from './VisitForm'
import { updateVisit } from '../services'
import { handleError } from '../../../helpers'

export default function EditVisitModal({ visible, onClose, reload, visit }) {
	const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm()

	const onSubmit = values => {
		updateVisit(visit.id, values)
			.then(() => {
				message.success('Visita actualizada exitosamente.')
				reset()
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Visita</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<VisitForm register={register} errors={errors} visit={visit} setValue={setValue} />
						<Button type="submit" color="primary">Actualizar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}