import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import TicketCategoryForm from './TicketCategoryForm'
import { storeTicketCategory } from '../services'
import { handleError } from '../../../helpers'

export default function NewTicketCategoryForm({ reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm();

	const onSubmit = values => {
		storeTicketCategory(values)
			.then(() => {
				message.success('Categoría creada exitosamente.')
				reload()
				reset()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<TicketCategoryForm register={register} errors={errors} />
				<Button type="submit" color="primary">Crear Categoría de Incidencia</Button>
			</Form>
		</React.Fragment>
	)
}