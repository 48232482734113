import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Spinner } from 'react-bootstrap'

import { getCompetitors } from '../../Competitors/services'
import { handleError } from '../../../helpers'

export default function CompetitorRateForm({ competitorRate, register, errors, watch }) {
	const [competitors, setCompetitors] = useState(null)
	const [selectedCompetitor, setSelectedCompetitor] = useState({})
	let selectedCompetitorId = watch('competitor_id')

	useEffect(() => {
		!competitors && getCompetitors({
			include: 'competitor_references'
		})
			.then(res => setCompetitors(res.data.data))
			.catch(error => handleError(error))
	}, [ competitors ])

	useEffect(() => {		
		competitors && selectedCompetitorId && selectedCompetitorId !== selectedCompetitor.id && 
			setSelectedCompetitor( competitors.filter(c => c.id === parseInt(selectedCompetitorId))[0] )
	}, [ setSelectedCompetitor, competitors, selectedCompetitorId, selectedCompetitor ])

	if(!competitors)
		return <Spinner />

	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Competidor</Form.Label>
				<Form.Control 
					as="select" 
					{...register('competitor_id', { required: true })} 
					defaultValue={competitorRate && competitorRate.competitor_id}
				>
					<option value="">:: Eliga una opción ::</option>
					{ competitors.map(competitor => <option key={competitor.id} value={competitor.id}>{competitor.name}</option>) }
				</Form.Control>
				{ errors.competitor_id && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Referencia <span className="text-danger">*</span></Form.Label>
				{ selectedCompetitor.competitor_references ?  (
					<React.Fragment>
						<Form.Control
							as="select"
							{...register('competitor_reference_id', { required: true })}
						>
							<option value="">:: Eliga una opción ::</option>
							{ selectedCompetitor.competitor_references.map( reference => (
								<option key={reference.id} value={reference.id}>
									{reference.name}
								</option>
							)) }
						</Form.Control>
						{ errors.competitor_reference_id && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
					</React.Fragment>
				) : (
					<Form.Text className="text-muted">Seleccione un competidor primero</Form.Text>
				)}
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>PVP Full <span className="text-danger">*</span></Form.Label>
					<Form.Control 
						{...register("pvp_full", {required: true})} 
						defaultValue={competitorRate && competitorRate.pvp_full} 
						type="number"
					/>
					{ errors.pvp_full && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>PVP con descuento <span className="text-danger">*</span></Form.Label>
					<Form.Control 
						{...register("pvp_dcto", {required: true})} 
						defaultValue={competitorRate && competitorRate.pvp_dcto} 
						type="number"
					/>
					{ errors.pvp_dcto && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Canal</Form.Label>
					<Form.Control 
						as="select" 
						{...register('channel', { required: true })} 
						defaultValue={competitorRate && competitorRate.channel}
					>
						<option>RETAIL</option>
						<option>FALABELLA</option>
						<option>ALKOSTO</option>
						<option>HOMECENTER</option>
						<option>OLIMPICA</option>
						<option>ELECTROJAPONESA</option>
						<option>ALKOSTO NARIÑO</option>
						<option>CORBETA</option>
						<option>VITTAHOME</option>
						<option>ALKOMPRAR</option>
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>PVP Colchon + Base Cama</Form.Label>
					<Form.Control 
						{...register("pvp_combo")} 
						defaultValue={competitorRate && competitorRate.pvp_combo}
						type="number" 
					/>
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Promesa de entrega <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register("delivery")} 
					defaultValue={competitorRate && competitorRate.delivery}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Dinámica Comercial <small>(opcional)</small></Form.Label>
				<Form.Control 
					as="textarea"
					{...register("promo")}
					defaultValue={competitorRate && competitorRate.promo}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Observaciones <small>(opcional)</small></Form.Label>
				<Form.Control 
					as="textarea"
					{...register("comment")}
					defaultValue={competitorRate && competitorRate.comment}
				/>
			</Form.Group>
		</React.Fragment>
	)
}