import springApi from '../../services/springApi'

export const getVisits = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/visits', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeVisit = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/visits', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateVisit = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/visits/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteVisit = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/visits/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)