import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Tooltip, Divider, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faLockOpen, faTasks, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditTaskListModal from './EditTaskListModal'
import TaskTemplatesModal from './TaskTemplatesModal'
import { deleteTaskList } from '../services'
import { handleError } from '../../../helpers'
import ModelStoresModal from '../../Stores/partials/ModelStoresModal'

export default function TaskListsTable({ taskLists, reload }) {
	const user = useSelector(state => state.auth.user)
	const [editTaskList, setEditTaskList] = useState(null)
	const [editTemplatesList, setEditTemplatesList] = useState(null)
	const [modelStoresModal, setModelStoresModal] = useState(null)

	const handleDelete = id => {
		deleteTaskList(id)
			.then(() => {
				message.success('Listado de tareas eliminado exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Listado de Tareas',
			dataIndex: 'name'
		},
		{
			title: 'Tareas Asociadas',
			dataIndex: 'task_templates_count',
			render: count => `${count} tareas asociadas`
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: active => active ? 'Activo' : 'Inactivo'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar Nombre">
						<FontAwesomeIcon className="text-link" icon={faEdit} onClick={()=>setEditTaskList(record)} />
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Plantillas de Tareas">
						<FontAwesomeIcon className="text-link" icon={faTasks} onClick={()=>setEditTemplatesList(record)} />
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Tiendas Permitidas">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faLockOpen} 
							onClick={()=>setModelStoresModal({
								id: record.id,
								type: "TaskList",
							})}
						/>
					</Tooltip>
					{ record.task_templates_count === 0 && user.ranking === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar el listado de tareas?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(record.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={taskLists}
				columns={columns}
				rowKey={record => record.id}
			/>
			<EditTaskListModal 
				visible={editTaskList ? true : false}
				onClose={()=>setEditTaskList(null)}
				taskList={editTaskList}
				reload={reload}
			/>
			<TaskTemplatesModal 
				visible={editTemplatesList ? true : false}
				onClose={()=>{
					setEditTemplatesList(null)
					reload()
				}}
				taskList={editTemplatesList}
				reload={reload}
			/>
			<ModelStoresModal 
				visible={modelStoresModal ? true : false}
				onClose={() => setModelStoresModal(null)}
				{...modelStoresModal}
			/>
		</React.Fragment>
	)
}