import React, { useState, useEffect } from 'react'

import NewQuestionForm from './partials/NewQuestionForm'
import QuestionsTable from './partials/QuestionsTable'
import { getQuestions } from './services'
import { handleError } from '../../helpers'

export default function QuestionsList({ survey }) {
	const [questions, setQuestions] = useState(null)

	useEffect(()=>{
		!questions && getQuestions({
			'filter[survey_id]': survey.id			
		})
			.then(res => setQuestions(res.data.data))
			.catch(error => handleError(error))
	}, [questions, survey])

	return (
		<React.Fragment>
			<QuestionsTable 
				questions={questions} 
				reload={()=>setQuestions(null)}
			/>
			<NewQuestionForm 
				survey={survey}
				reload={()=>setQuestions(null)}
			/>
		</React.Fragment>
	)
}