import springApi from '../../services/springApi'

export const getTaskTemplates = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/task_templates', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeTaskTemplate = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/task_templates', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateTaskTemplate = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/task_templates/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteTaskTemplate = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/task_templates/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)