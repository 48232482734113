import React, { useState, useEffect } from 'react'

import NewTaskTemplateForm from './partials/NewTaskTemplateForm'
import TaskTemplatesTable from './partials/TaskTemplatesTable'
import { getTaskTemplates } from './services'
import { handleError } from '../../helpers'

export default function TaskTemplatesList({ taskListId }) {
	const [taskTemplates, setTaskTemplates] = useState(null)

	useEffect(()=>{
		!taskTemplates && getTaskTemplates({
			'filter[task_list_id]': taskListId
		})
			.then(res => setTaskTemplates(res.data.data))
			.catch(error => handleError(error))
	}, [taskTemplates, taskListId])

	return (
		<React.Fragment>
			<TaskTemplatesTable 
				taskTemplates={taskTemplates} 
				reload={()=>setTaskTemplates(null)}
			/>
			<NewTaskTemplateForm 
				taskListId={taskListId} 
				reload={()=>setTaskTemplates(null)}
			/>
		</React.Fragment>
	)
}