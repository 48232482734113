import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faEdit, faLockOpen, faTasks, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import Pagination from '../../../components/Pagination'
import ModelStoresModal from '../../Stores/partials/ModelStoresModal'
import CommentsModal from '../../Comments/partials/CommentsModal'
import EditPromoModal from './EditPromoModal'
import PromoDetailsModal from './PromoDetailsModal'

import { deletePromo } from '../services'
import { handleError } from '../../../helpers'

export default function PromosTable({ promos, reload, pagination, setPagination }) {
	const user = useSelector(state => state.auth.user)
	const [editPromo, setEditPromo] = useState(null)
	const [detailsModal, setDetailsModal] = useState(null)
	const [commentsModal, setCommentsModal] = useState(null)
	const [modelStoresModal, setModelStoresModal] = useState(null)

	const handleDelete = id => {
		deletePromo(id)
			.then(() => {
				message.success('Dinámica eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Marca',
			dataIndex: 'brand',
		},
		{
			title: 'Nombre',
			dataIndex: 'name'
		},
		{
			title: 'Fecha de Inicio',
			dataIndex: 'start_at',
			render: t => moment(t).format('YYYY-MMM-DD')
		},
		{
			title: 'Válida Hasta',
			dataIndex: 'end_at',
			render: t => moment(t).format('YYYY-MMM-DD')
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: t => t ? 'Activa' : 'Inactiva'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							icon={faEdit} 
							className="text-link"
							onClick={() => setEditPromo(record)}
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Detalles">
						<FontAwesomeIcon 
							icon={faTasks}
							className="text-link"
							onClick={() => setDetailsModal(record)}
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Comentarios">
						<FontAwesomeIcon 
							icon={faComments} 
							className="text-link"
							onClick={() => setCommentsModal(record)}
						/>
					</Tooltip>
					<Divider type="vertical" />					
					<Tooltip title="Tiendas Permitidas">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faLockOpen} 
							onClick={()=>setModelStoresModal({
								id: record.id,
								type: "Promo",
							})}
						/>
					</Tooltip>
					{ (user.ranking === 'admin' || user.ranking === 'trade') && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm
								title="¿Segura desea eliminarla?"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDelete(record.id)}
							>
								<FontAwesomeIcon icon={faTrash} className="text-link" />
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				columns={columns}
				dataSource={promos}
				rowKey={record => record.id}
				locale={{ emptyText: 'No se encontraron dinámicas' }}
				pagination={false}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			<EditPromoModal 
				visible={editPromo ? true : false}
				onClose={() => setEditPromo(null)}
				reload={reload}
				promo={editPromo}
			/>
			<PromoDetailsModal 
				visible={detailsModal ? true : false}
				onClose={() => setDetailsModal(null)}
				promo={detailsModal}
			/>
			<CommentsModal 
				visible={commentsModal ? true : false}
				onClose={() => setCommentsModal(null)}
				modelType="App\Models\Promo"
				modelId={commentsModal && commentsModal.id}
				comments={commentsModal && commentsModal.comments}
				reload={reload}
			/>
			<ModelStoresModal 
				visible={modelStoresModal ? true : false}
				onClose={() => setModelStoresModal(null)}
				{...modelStoresModal}
			/>
		</React.Fragment>
	)
}