import React from 'react'
import { Form } from 'react-bootstrap'

export default function PromoDetailForm({ promoDetail, register, errors }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Descripción <span className="text-primary">*</span></Form.Label>
				<Form.Control 
					{...register("description", { required: true })} 
					defaultValue={promoDetail && promoDetail.description}
					as="textarea"
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Material de Comunicación</Form.Label>
				<Form.Control 
					{...register("sales_tools")} 
					defaultValue={promoDetail && promoDetail.sales_tools}
					as="textarea"
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Términos y Condiciones (opcional)</Form.Label>
				<Form.Control 
					{...register("terms")} 
					defaultValue={promoDetail && promoDetail.terms}
					as="textarea"
				/>
			</Form.Group>
		</React.Fragment>
	)
}