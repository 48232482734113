import React, { useState } from 'react'
import { Divider, Empty, Table, Tooltip} from 'antd'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faUsers } from '@fortawesome/free-solid-svg-icons'

import Pagination from '../../../components/Pagination'
import EditStoreModal from './EditStoreModal'
import StoreUsersModal from './StoreUsersModal'

export default function StoresTable({ stores, reload, pagination, setPagination }) {
	const [editStore, setEditStore] = useState(null)
	const [storeUsersModal, setStoreUsersModal] = useState(null)

	const columns = [
		{
			title: 'Nombre de Tienda',
			dataIndex: 'name',
			render: t => t ? t : <em className="text-muted">Sin especificar</em>
		},
		{
			title: 'Código',
			dataIndex: 'code'
		},
		{
			title: 'Regional',
			dataIndex: 'region',
			render: t => t ? t : <em className="text-muted">Sin especificar</em>
		},
		{
			title: 'Ciudad',
			dataIndex: 'city',
			render: t => t ? t : <em className="text-muted">Sin especificar</em>
		},
		{
			title: 'Categoría',
			dataIndex: 'type',
			render: t => t ? t : <em className="text-muted">Sin especificar</em>
		},
		{
			title: 'Celular',
			dataIndex: 'mobile',
			render: t => t ? t : <em className="text-muted">Sin especificar</em>
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar Tienda">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit} 
							onClick={()=>setEditStore(record)} 
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Usuario Asociados">
						<FontAwesomeIcon 
							className="text-link"
							icon={faUsers}
							onClick={() => setStoreUsersModal(record.id)}
						/>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const StoreDetails = ({ store }) => (
		<React.Fragment>
			<Row>
				<Col>
					<p className="mb-2">
						<strong>Dirección: </strong>
						{store.address ? `${store.address } - ${store.city}` : <em>Sin registro</em>}
					</p>
					<p className="mb-2">
						<strong>Horario: </strong>
						{store.schedule ? store.schedule : <em>Sin registro</em>}
					</p>
					<p className="mb-2">
						<strong>Correo Electrónico: </strong>
						{store.email ? store.email : <em>Sin registro</em>}
					</p>
				</Col>
				<Col>
					<p className="mb-2">
						<strong>Frecuencia de Visitas Sugeridas: </strong>
						{store.visit_frecuence ? store.visit_frecuence : <em>Sin registro</em>}
					</p>
					<p className="mb-2">
						<strong>Teléfono Fijo: </strong>
						{store.phone ? store.phone : <em>Sin registro</em>}
					</p>
					<p className="mb-2">
						<strong>Canal Registrado: </strong>
						{store.channel ? store.channel : <em>Sin registro</em>}
					</p>
					<p className="mb-2">
						<strong>Marca Registrada: </strong>
						{store.brand ? store.brand : <em>Sin registro</em>}
					</p>
				</Col>
			</Row>
		</React.Fragment>
	)

	return (
		<React.Fragment>
			<Table 
				columns={columns}
				dataSource={stores}
				rowKey={record => record.id}
				locale={{ emptyText: <Empty description='No se encontraron tiendas' /> }}
				pagination={false}
				size="middle"
				expandable={{ 
					expandedRowRender: record => <StoreDetails store={record} />
				}}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ editStore && (
				<EditStoreModal 
					visible={true}
					onClose={() => setEditStore(null)}
					reload={reload}
					store={editStore}
				/>
			)}
			{ storeUsersModal && (
				<StoreUsersModal
					visible={true}
					onClose={() => setStoreUsersModal(null)}
					storeId={storeUsersModal}
				/>
			)}
		</React.Fragment>
	)
}