import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import CheckinsList from './CheckinsList'

export default function Checkins() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/checkins" component={CheckinsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}