import moment from 'moment'
import React from 'react'

export default function CommentCard({ comment, reload }) {
	return (
		<React.Fragment>
			<div className="mb-2 p-2 bg-light">
				<small className="text-muted">
					{moment(comment.created_at).format('YYYY-MM-DD hh:mm a')}
				</small>
				<p className="mb-0">{comment.body}</p>
				<small className="text-muted">{comment.author_name}</small>
			</div>
		</React.Fragment>
	)
}