import springApi from '../../services/springApi'

export const getCompetitors = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/competitors', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCompetitor = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/competitors', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCompetitor = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/competitors/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCompetitor = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/competitors/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)