import React from 'react'
import { useSelector } from 'react-redux'
import { message, Popconfirm, Table } from 'antd'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import Pagination from '../../../components/Pagination'
import { deleteMetricResponse } from '../services'
import { handleError } from '../../../helpers'

export default function MetricResponsesTable({ metricResponses, reload, pagination, setPagination }) {
	const user = useSelector(state => state.auth.user)

	const handleDelete = id => {
		deleteMetricResponse(id)
			.then(res => {
				message.success('Respuesta eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Nombre del Listado',
			dataIndex: 'metric_list',
			render: t => t.name
		},
		{
			title: 'Usuario',
			dataIndex: 'user',
			render: t => t.name
		},
		{
			title: 'Tienda',
			dataIndex: 'store',
			render: t => t.name
		},
		{
			title: 'Registrado el',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MMM-DD hh:mm A')
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					{ user.ranking === 'admin' && (
						<Popconfirm
							title="¿Seguro desea eliminar la respuesta?"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(record.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon color="primary" icon={faTrash} />
						</Popconfirm>
					)}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={metricResponses}
				rowKey={record => record.id}
				columns={columns}
				pagination={false}
				expandable={{
					expandedRowRender: record => (
						<div>
							{ record.metrics.map( metric => (
								<p key={metric.id}>
									<strong>{metric.metric_template.name}:{" "}
									</strong>{metric.value}
								</p>
							)) }
						</div>
					)
				}}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
		</React.Fragment>
	)
}