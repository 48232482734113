import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, CardTitle, CardSubtitle, Button } from 'reactstrap'
import { InputGroup, Form } from 'react-bootstrap'

import TaskResponsesTable from './partials/TaskResponsesTable'
import { getTaskResponses } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function TaskResponsesList() {
	const [taskResponses, setTaskResponses] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [filterType, setFilterType] = useState('user.name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(()=>{
		!taskResponses && getTaskResponses({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'task_list,user,tasks'
		})
			.then(res => {
				setTaskResponses(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [taskResponses, pagination, filterType, filterValue])

	return (
		<React.Fragment>
			<Card>
				<CardHeader>
					<div className="float-right mt-2">
						<InputGroup>
							<InputGroup.Prepend>
								<Form.Control 
									as="select" 
									className="bg-light"
									value={filterType}
									onChange={e => setFilterType(e.target.value)}
								>
									<option value="user.name">Buscar por nombre de usuario</option>
									<option value="task_list.name">Buscar por nombre de listado</option>
									<option value="created_at">Buscar por fecha (YYYY-MM-DD)</option>
								</Form.Control>
							</InputGroup.Prepend>
							<Form.Control 
								placeholder="Escriba aquí" 
								value={filterValue}
								onChange={e => setFilterValue(e.target.value)}
							/>
							<InputGroup.Append>
								<Button color="primary" onClick={()=>setTaskResponses(null)}>Buscar</Button>
							</InputGroup.Append>
						</InputGroup>
					</div>
					<CardTitle>Listado de Tareas Registradas</CardTitle>
					<CardSubtitle>Listados de tareas registrados desde el aplicativo</CardSubtitle>
				</CardHeader>
				<CardBody>
					<TaskResponsesTable 
						taskResponses={taskResponses} 
						reload={()=>setTaskResponses(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}