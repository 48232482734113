import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Tooltip, Divider, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditQuestionModal from './EditQuestionModal'
import { deleteQuestion } from '../services'
import { handleError } from '../../../helpers'

export default function QuestionsTable({ questions, reload }) {
	const user = useSelector(state => state.auth.user)
	const [editQuestion, setEditQuestion] = useState(null)

	const handleDelete = id => {
		deleteQuestion(id)
			.then(() => {
				message.success('Pregunta eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Pregunta',
			dataIndex: 'name'
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: active => active ? 'Activa' : 'Inactiva'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar Tarea">
						<FontAwesomeIcon className="text-link" icon={faEdit} onClick={()=>setEditQuestion(record)} />
					</Tooltip>
					<Divider type="vertical" />
					{ user.ranking === 'admin' && <Popconfirm
						title="¿Desea eliminar la pregunta?"
						okText="Eliminar"
						cancelText="Cancelar"
						onConfirm={() => handleDelete(record.id)}
						okButtonProps={{ danger:true }}
					>
						<FontAwesomeIcon className="text-link" icon={faTrash} />
					</Popconfirm> }
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={questions}
				columns={columns}
				rowKey={record => record.id}
				size="small"
				expandable={{
					expandedRowRender: record => <p className="mb-0">
						<strong>Descripción:</strong> {record.description ? record.description : 'Sin descripión'}
					</p>,
				 }}
			/>
			<EditQuestionModal 
				visible={editQuestion ? true : false}
				onClose={()=>setEditQuestion(null)}
				question={editQuestion}
				reload={reload}
			/>
		</React.Fragment>
	)
}