import springApi from '../../services/springApi'

export const getMetricLists = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/metric_lists', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeMetricList = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/metric_lists', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateMetricList = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/metric_lists/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteMetricList = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/metric_lists/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)