import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import PromoDetailForm from './PromoDetailForm'
import { updatePromoDetail } from '../services'
import { handleError } from '../../../helpers'

export default function EditPromoDetailModal({ visible, onClose, promoDetail, reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		updatePromoDetail(promoDetail.id, values)
			.then(() => {
				message.success('Detalle de dinámica actualizado exitosamente.')
				reload()
				reset()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Detalle de Dinámica</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<PromoDetailForm promoDetail={promoDetail} register={register} errors={errors} />
						<Button type="submit" color="primary">Actualizar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}