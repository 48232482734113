import React, { useState } from 'react'
import { Table, Tooltip, Divider, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditTaskTemplateModal from './EditTaskTemplateModal'
import { deleteTaskTemplate } from '../services'
import { handleError } from '../../../helpers'

export default function TaskTemplatesTable({ taskTemplates, reload }) {
	const [editTaskTemplate, setEditTaskTemplate] = useState(null)

	const handleDelete = id => {
		deleteTaskTemplate(id)
			.then(() => {
				message.success('Tarea eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Tarea',
			dataIndex: 'name'
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: active => active ? 'Activa' : 'Inactiva'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar Tarea">
						<FontAwesomeIcon className="text-link" icon={faEdit} onClick={()=>setEditTaskTemplate(record)} />
					</Tooltip>
					<Divider type="vertical" />
					<Popconfirm 
						title="¿Desea eliminar tarea?"
						okText="Eliminar"
						cancelText="Cancelar"
						onConfirm={() => handleDelete(record.id)}
						okButtonProps={{ danger:true }}
					>
						<FontAwesomeIcon className="text-link" icon={faTrash} />
					</Popconfirm>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={taskTemplates}
				columns={columns}
				rowKey={record => record.id}
				size="small"
				expandable={{
					expandedRowRender: record => <p className="mb-0">
						<strong>Descripción:</strong> {record.description ? record.description : 'Sin descripión'}
					</p>,
				 }}
			/>
			<EditTaskTemplateModal 
				visible={editTaskTemplate ? true : false}
				onClose={()=>setEditTaskTemplate(null)}
				taskTemplate={editTaskTemplate}
				reload={reload}
			/>
		</React.Fragment>
	)
}