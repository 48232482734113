import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { Empty } from 'antd'

import ProductsTable from './partials/ProductsTable'
import ListTopBar from '../../components/ListTopBar'

import { getProducts } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ProductsList() {
	const currentUserRole = useSelector(state => state.auth.user.role)
	const [products, setProducts] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!products && getProducts({ 
			...pagination, 
			[`filter[${filterType}]`]: filterValue,
			'filter[distinct_references]': true,
			sort: '-created_at'
		})
			.then(res => {
				setProducts(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(handleError)
	}, [ products, pagination, filterType, filterValue ])

	if(!['admin','director','trade','kam'].includes(currentUserRole))
		return <Empty description="Su usario no posee permisos para ingresar a este módulo" />

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setProducts(null)}
				title="Referencia de Productos Spring"
				subtitle="Referencias cotizador web Spring"
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por referencia', value: 'reference' },
				]}
			/>
			<Card>
				<CardBody>
					<ProductsTable 
						products={products}
						pagination={pagination}
						setPagination={setPagination}
						reload={() => setProducts(null)}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}