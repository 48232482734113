import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import TaskTemplatesList from '../../TaskTemplates/TaskTemplatesList'

export default function TaskTemplatesModal({ visible, onClose, taskList, reload }) {

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="lg">
				<ModalHeader toggle={onClose}>Tareas Asociadas</ModalHeader>
				<ModalBody>
					<TaskTemplatesList 
						taskListId={taskList && taskList.id}
					/>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}