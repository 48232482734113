import React, { useState, useEffect } from 'react'

import NewMetricTemplateForm from './partials/NewMetricTemplateForm'
import MetricTemplatesTable from './partials/MetricTemplatesTable'
import { getMetricTemplates } from './services'
import { handleError } from '../../helpers'

export default function MetricTemplatesList({ metricListId }) {
	const [metricTemplates, setMetricTemplates] = useState(null)

	useEffect(()=>{
		!metricTemplates && getMetricTemplates({
			'filter[metric_list_id]': metricListId
		})
			.then(res => setMetricTemplates(res.data.data))
			.catch(error => handleError(error))
	}, [metricTemplates, metricListId])

	return (
		<React.Fragment>
			<MetricTemplatesTable 
				metricTemplates={metricTemplates} 
				reload={()=>setMetricTemplates(null)}
			/>
			<NewMetricTemplateForm 
				metricListId={metricListId} 
				reload={()=>setMetricTemplates(null)}
			/>
		</React.Fragment>
	)
}