import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'
import { handleError } from '../../helpers'

import NewTicketCategoryForm from './partials/NewTicketCategoryForm'
import TicketCategoriesTable from './partials/TicketCategoriesTable'
import { getTicketCategories } from './services'

export default function TicketCategoriesList() {
	const [ticketCategories, setTicketCategories] = useState(null)

	useEffect(()=>{
		!ticketCategories && getTicketCategories({
			include: 'ticketsCount'
		})
			.then(res => setTicketCategories(res.data.data))
			.catch(error => handleError(error))
	}, [ticketCategories])

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Categoría</CardTitle>
							<CardSubtitle>Ingrese los datos nuevos</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewTicketCategoryForm reload={()=>setTicketCategories(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="7">
					<Card>
						<CardHeader>
							<CardTitle>Listado de Categorías de Incidencias</CardTitle>
							<CardSubtitle>Administre las categorías de incidencias existentes</CardSubtitle>
						</CardHeader>
						<CardBody>
							<TicketCategoriesTable ticketCategories={ticketCategories} reload={()=>setTicketCategories(null)} />
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</React.Fragment>
	)
}